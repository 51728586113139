import React, {useEffect} from "react";
import DestinationCard from "../SouthAfricaCard";
import Navbar from "../Navbar";
import Footer from "../Footer";
import "./packages.css";
import { HelmetProvider, Helmet } from 'react-helmet-async';
import HomeSlider from '../HomeSlider';

const images = [
  "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d/o/tu-africa%2Fa-1-new.webp?alt=media&token=005476e0-674d-4e02-8b73-7ed5b64b1cf4",
  "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d/o/tu-africa%2Fsouth-africa-banner.webp?alt=media&token=e40c56a7-35b2-49b7-941d-b2b543a75658",
  "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d/o/tu-africa%2Fa-4-1.webp?alt=media&token=2e6c2ac2-79bc-4626-96df-b41a01029853",
  "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d/o/tu-africa%2Fa-5-4.webp?alt=media&token=293b54a1-ef7e-4b66-8975-03f0824a7c30",
]

const preloadImages = (imageUrls) => {
  
  imageUrls.forEach((url) => {
    const img = new Image();
    img.src = url;
  });
};

const HotSellingPackages = () => {
  
  useEffect(() => {
    const allImages = [...images];
    preloadImages(allImages);
  }, []);

  return (
    <>
    <HelmetProvider>
      <Helmet>
        <title>South Africa Tour Packages by Travel Unbounded</title>
        <meta
          name="description"
          content="Discover extraordinary wildlife with Travel Unbounded. Our expert guides lead tailor-made safari tours, promising moments of awe and wonder. Book now for an immersive journey into nature's wonders."
        />
      </Helmet>
      <Navbar />
      <HomeSlider images={images} showOverlayText={false}/>

      <h1 className="text-center package-head mt-5 mb-5">South Africa Tours</h1>
      <div className="destinations-container">
        <DestinationCard />
      </div>
      <Footer />
      </HelmetProvider>
    </>
  );
};
export default HotSellingPackages;
