import React, { useState } from 'react';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { toast, Toaster } from "react-hot-toast";
import './index.css';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

const CustomForm = () => {
  const [phone, setPhone] = useState('');
  const [formData, setFormData] = useState({
    name: "",
    city: "",
    email: "",
    phone: "",
    destination: "",
    dates: "",
    noOfPeople: "",
    type: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handlePhoneChange = (value) => {
    setPhone(value);
    setFormData((prevFormData) => ({
      ...prevFormData,
      phone: value,
    }));
  };

  const handleSubmit = async (e, setIsFormOpen) => {
    e.preventDefault();
    try {
      const db = firebase.firestore();
      db.collection("EnquireFormTU").add(formData);

      const response = await fetch("https://formbackend.netlify.app/enquire-now", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      const data = await response.json();
      console.log(data.message);
      toast.success("Enquiry Submitted Successfully");
      setIsFormOpen(false);
      setFormData({
        name: "",
        city: "",
        email: "",
        phone: "",
        destination: "",
        dates: "",
        noOfPeople: "",
        type: "",
      });
      setPhone('');
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  return (
    <>
      <Toaster toastOptions={{ duration: 5000 }} />
      <div className="form-container">
        <form onSubmit={handleSubmit}>
          <div className='enquire-head'>
            <h3>Enquire Now</h3>
          </div>
          <input 
            type="text" 
            name="name" 
            placeholder="Name" 
            className="form-input" 
            value={formData.name} 
            onChange={handleChange} 
            required
          />
          <input 
            type="text" 
            name="city" 
            placeholder="City of Residence" 
            className="form-input" 
            value={formData.city} 
            onChange={handleChange} 
            required
          />
          <input 
            type="email" 
            name="email" 
            placeholder="Email" 
            className="form-input" 
            value={formData.email} 
            onChange={handleChange} 
            required
          />

          <div className="phone-input-container">
            <PhoneInput
              international
              defaultCountry="IN"
              value={phone}
              onChange={handlePhoneChange}
              className="phone-input"
              required
            />
            {!phone && <span className="placeholder-text">Phone Number</span>}
          </div>

          <input 
            type="text" 
            name="destination" 
            placeholder="Travel Destination" 
            className="form-input" 
            value={formData.destination} 
            onChange={handleChange} 
          />

          <input
            type="text"
            name="dates"
            placeholder="Travel Dates"
            onFocus={(e) => (e.target.type = 'date')}
            onBlur={(e) => (e.target.value === '' ? (e.target.type = 'text') : null)}
            className="form-input"
            onChange={handleChange}
            value={formData.dates}
            required
          />

          <input 
            type="number" 
            name="noOfPeople" 
            placeholder="No. of People" 
            className="form-input" 
            value={formData.noOfPeople} 
            onChange={handleChange} 
            required
          />

          <select 
            name="type" 
            className="form-input" 
            value={formData.type} 
            onChange={handleChange}
            required
          >
            <option value="">Vacation Type</option>
            <option value="Family">Family</option>
            <option value="Honeymoon">Honeymoon</option>
            <option value="Adventure">Adventure</option>
            <option value="Relaxation">Relaxation</option>
          </select>
          <button type="submit" className="submit-button">SUBMIT</button>
        </form>
      </div>
    </>
  );
};

export default CustomForm;

