import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import Navbar from "../Navbar";
import Footer from "../Footer";
import "./index.css";
import HomeSlider from "../HomeSlider";
//import { IoMdHome } from "react-icons/io";
import { HelmetProvider, Helmet } from "react-helmet-async";

const images = [
    "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d/o/tu-packages%2F1.avif?alt=media&token=a1215e26-6c86-42a9-b5a5-000cca923c3d",
    "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/TU-Packages%2F2.jpg?alt=media&token=1ffd52df-f203-4ac1-9ef7-19f18121df95",
    "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/TU-Packages%2F3.jpg?alt=media&token=02d3dea4-2d4a-4621-9d37-ba5755c8539d",
    "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/TU-Packages%2F4.jpg?alt=media&token=12a81737-93e8-4b22-87d4-622a63322dc1",
    "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/TU-Packages%2F5.jpg?alt=media&token=df7f52a9-ba59-4833-bff3-28373a945a83",
    "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/TU-Packages%2F6.jpg?alt=media&token=8c3b29c2-6215-4cc0-8a72-4f947eea3c2b",
    "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/TU-Packages%2F7.jpg?alt=media&token=4859ee00-1ce1-4d83-916d-71ea75c7db9b",
    "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d/o/tu-packages%2F8.webp?alt=media&token=e823c90f-b626-4f7c-acee-f662c61a3c39",
    "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d/o/tu-packages%2F9.webp?alt=media&token=090abad3-8d3f-4fde-8fd8-1356a0af79b3",
  ]

const preloadImages = (imageUrls) => {
  imageUrls.forEach((url) => {
    const img = new Image();
    img.src = url;
  });
};

const HotSellingPackages = () => {
  const [packages, setPackages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    preloadImages(images);
    const fetchPackages = async () => {
      const db = firebase.firestore();
      try {
        const snapshot = await db.collection("PackageTemplate").get();
        const packageList = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setPackages(packageList);
      } catch (error) {
        console.error("Error fetching packages:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchPackages();
  }, []);

  if (isLoading) {
    return(
      <div className='loading-container'>
      <p className='loading'></p>
      </div>
    )
  }

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Curated Journeys by Travel Unbounded</title>
          <meta
            name="description"
            content="Discover extraordinary curated journeys with Travel Unbounded. Book Now for an immersive experience"
          />
          <link
            rel="preload"
            as="image"
            fetchPriority="high"
            href="https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d/o/tu-packages%2F1.avif?alt=media&token=a1215e26-6c86-42a9-b5a5-000cca923c3d"
          />
        </Helmet>
        <Navbar />
        <HomeSlider images={images} showOverlayText={false} />
        <h1 className="text-center hot-package-head mt-5 mb-5">
          Best Selling Tour Packages
        </h1>
        <div className="destinations-container">
          <div className="best-package-list">
            {packages.map((pkg) => (
              <div key={pkg.id} className="package-list-card mb-3">
                <img
                  src={pkg.bannerImageUrl}
                  alt={pkg.destinationName}
                  className="list-card-img mb-3"
                />
                <div>
                  <h4 className="title-list mb-3">{pkg.destinationHead}</h4>
                  <h5 className="title-dates-list bold mb-3">{pkg.noOfDays}</h5>
                  <h5 className="title-rate-list bold mb-3">{pkg.cost}</h5>
                  <div className="btn-container-list-card">
                    <Link
                      to={`/package/${pkg.destinationName.replace(/\s+/g, "-")}`}
                      className="blogs-link"
                    >
                      <button className="view-button-list-card">
                        View Itinerary
                      </button>
                    </Link>
                    <Link to="/form" className="blogs-link">
                      <button className="view-button-list-card-book">
                        Book Now
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <Footer />
      </HelmetProvider>
    </>
  );
};

export default HotSellingPackages;
