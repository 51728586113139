import React, { useState } from "react";
import { NavLink, Link } from "react-router-dom";
import { FiMenu, FiX } from "react-icons/fi";
import { FaSearch } from "react-icons/fa";
import { FaWhatsapp, FaInstagram } from "react-icons/fa";
import "./nav.css";
import { AiOutlineLinkedin, AiOutlineFacebook } from "react-icons/ai";
import Gallery from "../Gallery";
import SearchOverlay from "../SearchOverlay";

const images = [
  "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d/o/tu-home%2Ftu-home-imgs%2Freview-1.jpg?alt=media&token=dd4b556b-d21f-45ec-83e8-fb43037199fd",
  "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d/o/tu-home%2Ftu-home-imgs%2Freview-2.jpg?alt=media&token=60ed7105-ddb1-43df-bdb6-dc2b292dbd4b",
  "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d/o/tu-home%2Ftu-home-imgs%2Freview-3.jpg?alt=media&token=d5b9722c-701c-4c44-918c-40cbd05d263c",
  "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d/o/tu-home%2Ftu-home-imgs%2Freview-4.jpg?alt=media&token=6c082e12-bf22-4bf0-80d4-43ecd7f13d16",
];

function Navbar() {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  const [isOverlayVisible, setIsOverlayVisible] = useState(false);

  const handleSearchClick = () => {
    setIsOverlayVisible(true);
  };

  const handleCloseOverlay = () => {
    setIsOverlayVisible(false);
  };
  return (
    <div className="navbar mr-3 ml-3">
      <Link to="/">
        <div className="logo">
        <picture>
          <source srcSet="https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d/o/tu-home%2Ftu-home-imgs%2Flogo.avif?alt=media&token=5a46e44a-ee72-4919-a646-8b2068a53040" type="image/avif" />
          <img
            src="/home/logo.png"
            alt="logo"
            className="logo-img"
          />
          </picture>
        </div>
      </Link>
      <div className="nav-right">
        <Link to="/form">
          <button type="button" className="nav-btn">
            Book Now
          </button>
        </Link>
        <FaSearch
          size={20}
          className="search-icon"
          onClick={handleSearchClick}
        />
        {isOverlayVisible && <SearchOverlay onClose={handleCloseOverlay} />}

        <div className="menu-icon" onClick={toggleMenu}>
          {menuOpen ? (
            <FiX size={30} onClick={closeMenu} />
          ) : (
            <FiMenu size={30} />
          )}
        </div>
      </div>
      {menuOpen && (
        <div className="overlay">
          <div className="left-content"></div>
          <div className="menu">
            <div className="close-button" onClick={closeMenu}>
              <FiX size={40} />
            </div>

            <ul className="list-items">
              <NavLink to="/" className="dest" activeclassname="active">
                <li className="nav-buttons"> Home </li>
              </NavLink>
              <NavLink to="/packages" className="dest" activeclassname="active">
                <li className="nav-buttons">Best Selling Tour Packages</li>
              </NavLink>
              <NavLink to="/south-africa-tours" className="dest" activeclassname="active">
                <li className="nav-buttons">South Africa Tours</li>
              </NavLink>
              <NavLink to="/blogs" className="dest" activeclassname="active">
                <li className="nav-buttons"> Blogs </li>
              </NavLink>
              <NavLink to="/about" className="dest" activeclassname="active">
                <li className="nav-buttons"> About Us </li>
              </NavLink>
              <NavLink to="/contact" className="dest" activeclassname="active">
                <li className="nav-buttons"> Contact Us </li>
              </NavLink>
              <li>
                <div className="icons-container">
                  <a href="https://wa.me/9663579014" target="__blank">
                    <div className="nav-icon">
                      <FaWhatsapp size={30} />
                    </div>
                  </a>
                  <a
                    href="https://www.instagram.com/travel_ub/"
                    target="__blank"
                  >
                    <div className="nav-icon">
                      <FaInstagram size={30} />
                    </div>
                  </a>
                  <a
                    href="https://www.facebook.com/travelunboundedcom"
                    target="__blank"
                  >
                    <div className="nav-icon">
                      <AiOutlineFacebook size={20} />
                    </div>
                  </a>
                  <a
                    href="https://www.linkedin.com/company/travel-unbounded/"
                    target="__blank"
                  >
                    <div className="nav-icon">
                      <AiOutlineLinkedin size={30} />
                    </div>
                  </a>
                </div>
              </li>
            </ul>
          </div>
          <div className="right-content">
            <div className="right-content-flex">
              <Gallery images={images} />
              <h5
                className="mt-5 text-center nav-buttons"
                style={{ color: "white", fontWeight: "bold" }}
              >
                Write a review to Scan
              </h5>
              <div className="text-center">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d/o/tu-home%2Ftu-home-imgs%2Fg-scan.jpeg?alt=media&token=d3780f53-d771-44b1-ba30-596effc430f4"
                  alt="scanner-reviews"
                  className="qr-code"
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
export default Navbar;
