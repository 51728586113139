import React, { useState, useEffect } from 'react';
import { FiMessageCircle, FiX } from 'react-icons/fi';
import EnquireForm from '../EnquireForm';
import './index.css';

const EnquireButton = () => {
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [hasScrolled, setHasScrolled] = useState(false);

  const toggleForm = () => {
    setIsFormOpen(!isFormOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 500 && !hasScrolled) {
        setIsFormOpen(true);
        setHasScrolled(true); 
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [hasScrolled]);

  return (
    <div className="enquire-button-container">
      {!isFormOpen && (
        <button className="enquire-button" onClick={toggleForm}>
          <FiMessageCircle className="enquire-icon" size={24} />
        </button>
      )}
      {isFormOpen && (
        <div className="enquire-form-overlay">
          <div className="enquire-form-container">
            <button className="close-button" onClick={toggleForm}>
              <FiX size={24} />
            </button>
            <EnquireForm />
          </div>
        </div>
      )}
    </div>
  );
};

export default EnquireButton;
