import React from "react";
import { Link } from "react-router-dom";
import "./index.css";
import { HelmetProvider, Helmet } from 'react-helmet-async';

const Blog10 = () => {
  return (
    <>
    <HelmetProvider>
      <Helmet>
        <title>Best Vietnam tours | Travel guide</title>
        <meta
          name="description"
          content="Discover Vietnam, the underrated paradise, with our top Vietnam tours. Explore stunning landscapes, rich culture, and hidden gems. Get the ultimate travel guide for an unforgettable adventure in this beautiful country."
        />
      </Helmet>
      <div className="container">
        <h1 className="text-center package-head mt-5">
       Best Vietnam tours | Travel guide
        </h1>
        <p className="list-style mt-3">
          I really don’t know why it took such a long time for me in my life to
          visit this Paradise called , Vietnam!! We were sending a lot of groups
          to this place and all of them who came back shared their fond
          memories. But it still didn’t strike me that I had to go here!!
        </p>
        <img src="/blogs/blog-10-1.jpg" alt="" className="blog-image" />
        <p className="list-style mt-3">
          My dear friend Manoharan who has started his new travel venture,
          suddenly came up with the idea to visit Vietnam. We are extremely busy
          with our East Africa tours from Jan till end October. And we also have
          a lot of corporate events which we conduct during the ﬁrst half of the
          year. I told him that he can visit. But while driving back that
          evening, a sudden thought ﬂashed in my mind that i should also join
          him. Half heartedly i took the decision. Mano as i call him was very
          happy to hear this news.
        </p>
        <p className="list-style">
          Our local partner, Thang curated an itinerary for us. Things started
          to fall in place. Mano went ahead and booked the tickets. But my work
          pressure was still very high. Heart of hearts, i still wasn’t
          convinced about this trip.
        </p>
        <p className="list-style">
          All these years of travel has made me prepare for any of my trip very
          well. But this wasn’t the case this time. Just 24 hours before travel,
          i pulled my suitcase, ﬁlled it with ‘some’ clothes without even
          checking on the weather. I also had my parents visit me during this
          time. This made me even more busy with personal and professional work.
        </p>
        <p className="list-style">
          The day arrived, i had to go to the airport. I reached Bangalore
          airport, and met Mano who travelled from his home town Coimbatore. I
          hadn’t met him for a long time, so i was really happy to meet him.
          After exchanging pleasantries, we sat down for a cup of coﬀee. Mano
          was suﬀering from a severe cold. When you aren’t keeping well,
          especially before a long travel, that puts you oﬀ. With this mood we
          boarded the ﬂight to Kolkatta. We landed and to our surprise, Kolkata
          airport was one of the worst kept ones. We had two hours to kill at a
          place where spending even ﬁve minutes is diﬃcult.
        </p>
        <img src="/blogs/blog-10-2.jpg" alt="" className="blog-image-2" />
        <p className="list-style mt-3">
          Finally we boarded the ﬂight to Hanoi. I had got a seat near the
          emergency exit, which gave me the much wanted leg room. I just
          stretched out without any thoughts. I woke up when the pilot put the
          lights on and announced that he would be touching down in the next ﬁve
          minutes. It was 2.10AM local time when we arrived. I was wearing my
          normal jacket. But when we got out of the airport, i realised that i
          had made a mistake. The weather was very cold!! It took less than a
          couple of minutes to get to our car. We got dropped at a hotel called
          ‘Thang Long Opera’. Both of us were tired bcos of the ﬂight. We just
          went and crashed out.
        </p>
        <p className="list-style">
          I get up very early in the mornings. But this day it was little late.
          It was about 5.30AM when i woke up. The room was cozy but the weather
          outside was freezing. Prepared my cup of coﬀee. This was the ﬁrst
          time, my thoughts about this place started to change. The coﬀee packet
          kept in the room was something i have never tasted before. It was a
          ready to make coﬀee sachet, which had milk, sugar and coﬀee in it.
          Hmmm, moods changed quickly.
        </p>
        <p className="list-style">
          I sent a message back home that i had reached. With nothing to do, i
          just lie down on my bed, thinking about my next eight days i was going
          to spend here. The morning was for us and we did not have anything
          special to do. The tour was supposed to start after lunch. Our guide,
          a pretty Vietnamese girl , ‘Smiley’, the name which suited her the
          best arrived at the lobby to meet us. We were guided to an Indian
          restaurant known as , ‘India Gate’. It was at a walkable distance from
          our place. I have had Indian food out side India, but this place
          ﬂoored me!! Phew, every taste buds in my mouth burst out with
          happiness. The hot Rotis, veg curry, steamed rice and Dal just blew
          me. The owner asked me to try the egg masala cos now i eat only eggs
          and no non veg. I requested him a small portion. Hmmm, this was
          another delicacy.
        </p>
        <img src="/blogs/blog-10-3.jpg" alt="" className="blog-image" />
        <p className="list-style mt-3">
          We were supposed to start our tour by 2PM, but the extended lunch made
          us start with heavy bellies only by 3.30PM!! Mano wasn’t feeling good.
          He had developed fever and was shivering in this cold. He wanted a
          thicker jacket.
        </p>
        <p className="list-style">
          Evening became very very cold. We went to the Old Quarter. The accent
          history and culture of this place has to be visited to really enjoy.
          Every street was lit. It was buzzing with tourists from all around the
          World. We took a ‘Rickshaw’, a cycle with three wheels, a carrier
          where the tourist sit and a seat for the driver to ride. It was a mid
          aged Vietnamese who was riding us. From his built, I thought we were
          making him suﬀer. But his stamina and power to peddle was close to a
          well trained athlete. Smiley was running along with us in that cold
          weather, smiling her way and also making some videos of us.
        </p>
        <video controls autoPlay muted preload="auto" src="/blogs/blog-10-4.mp4" type="video/mp4" className="vietnam-video"></video>
        <p className="list-style mt-3">
          The Old quarter in Hanoi is an intriguing neighbourhood. It has a
          great mix of green spaces, ancient shops and some of the best street
          food joints. You can smell the freshly prepared noodles on one street
          and a enjoy the beautiful fragrance of the ﬂowers in another. There
          are 36 streets in total, which were set up during the yesteryears to
          create high quality products to the Royals of Thang Long Citadel.
          Surprisingly , till today these shops have been producing the same
          products. The silversmiths who made money and jewellery for the
          Royals, still house some of the ﬁnest jewellery and have money
          changers!! The food stores in the sidewalks have low seats and
          benches. You can just sit down and enjoy a hot cup of noodle soup. If
          you are a non vegetarian, this place will blow your mind oﬀ.
          Vegetarians also have a lot to snack and eat.
        </p>
        <video controls autoPlay muted preload="auto" src="/blogs/blog-10-5.mp4" type="video/mp4" className="vietnam-video"></video>
        <Link to="/packages/vietnam">
          {" "}
          <h3 className="package-head text-center mt-5">
            ‘DO YOU WANT TO GO TO HANOI OLD QUARTER?’
          </h3>{" "}
        </Link>
        <p className="list-style mt-5">
          As the night approached the weather went cold to colder. I could see
          Mano shivering. He jumped out of the rickshaw and ran inside the shop
          to buy an ear cap and two caps. Such a sweet heart, with this
          condition, he picked up one cap for me too!! We told Smiley that we
          would not go for dinner but just take away our food to our hotel.
          India Gate again saved us with some hot Indian food.
        </p>
        <p className="list-style">
          We reached the hotel lobby. By that time i had made up my mind to
          cancel the next day trip to Halong Bay. I sent a message to Thang. I
          knew he was shell shocked. Halong Bay is the most famous destinations
          of Vietnam. I could see he was typing, typing, typing, typing and
          typing… Finally the reply came in. Mr. K like how he calls me,
          messaged, Sir, why don’t you do the trip alone? ‘No’ was my reply,
          followed by, Thang, ﬁrst is Mano, then the Bay!!
        </p>
        <p className="list-style">
          Smiley by that time knew that we both needed a good jacket. She called
          her friend, Minch. I saw this young, dashing, tall handsome Vietnamese
          guy come to the hotel. Mano by that time went to the room and had his
          medicines and slept. Minch knew the situation. He called for a CAB at
          that hour, rushed to a mall where i managed to buy some good thick
          jackets.
        </p>
        <p className="list-style">
          I was just amazed to see the rates. I would have easily paid INR 9000
          per jacket, but i picked by two for just INR 4000!!! I had asked Minch
          to try an XXL cos he was tall and heavily built. Smiley asked me to
          whom i was buying this one. I just smiled and told her that i would
          reply once we get back to the hotel. At the reception, i handed over
          the XXL jacket to Minch and told him that it was his. I have seen
          people get surprised and blush. This was the ﬁrst time i saw both the
          reactions from two people simultaneously!! Smiley gave a hug to me and
          said, i was too generous. I did not know whether i was generous or a
          little drunk!! But the day ended on a happy note. I came back to my
          room to ﬁnd Mano sleeping like a baby. But even in his sleep he was
          murmuring that i should go to Halong Bay alone.
        </p>
        <p className="list-style">
          Next day we got up as usual, Mano was slightly better. Thang came to
          meet me at 11AM at the hotel. This was the ﬁrst time i was meeting him
          in person. The respect he displayed was from the bottom of his heart.
          He had carried two packets of the ﬁnest coﬀee for us. After discussing
          for a long time, he bid good bye and told me to visit the tea factory
          and the train street. He had made alternate arrangements with another
          CAB. This was so helpful. To change the itinerary in such a short
          notice proved that they were true professionals.
        </p>
        <p className="list-style">
          We went to the train street. Phew, what an experience it was. Shops
          along a narrow train track, buzzing with tourists. Every shop you go,
          would serve you tea. The train was running late i suppose!! We
          couldn’t see the train passing by, but enjoyed the entire scene. We
          also got to know to check the train timings during weekdays and
          weekends to enjoy this place more.
        </p>
        <p className="list-style">
          The train street at Hanoi needs to have a special mention. From the
          olden days, people around the World have always built houses along the
          rivers, and rail tracks. This was to make transportation easy. Very
          near to the Old quarter is this train street. It’s a tiny narrow
          street surrounded by high narrow houses packed closely together. These
          families’ backyards are the railways where the train passes a few
          times per day. What makes this place attract hordes of tourists is
          that the train passes just a meter away from the houses!! Just before
          the train arrives, people ensure that the kids and pets are inside.
          The tourists are just centimetres away from the train, but still
          clicking pictures!! The ﬁrst storied houses have turned into fully
          blown restaurants serving beer and food. And as soon as the train
          passes by, life on the tracks is back again. You would see the bikes
          coming out of the houses, the pets and kids running on the track as
          though nothing had happened. An experience not to be missed.{" "}
        </p>
        <video controls autoPlay muted preload="auto" src="/blogs/blog-10-6.mp4" type="video/mp4" className="vietnam-video"></video>
        <Link to="/packages/vietnam">
          <h3 className="package-head text-center mt-5">
            ‘DO YOU WANT TO GO TO THE TRAIN STREET IN HANOI?’
          </h3>
        </Link>
        <p className="list-style mt-5">
          Dinner again was at the famous India Gate restaurant. By now we were
          regulars!! Night went by peacefully. It was an early morning start for
          us the next day after breakfast. We went by a taxi to a place called
          Nin Binh. It is known as the Halong Bay of the land. Smiley carried a
          JBL portable speaker. Smiley asked us to play some Tamil and Hindi
          songs via Bluetooth. I just saw the happiness in her face while we
          were playing our favourite tunes.
        </p>
        <p className="list-style">
          Nin Binh is a wonder on Earth. It is is a protected UNESCO World
          Heritage Site, full of mossy pagodas, striking limestone karsts, and
          surreal landscapes. Some of Ninh Binh’s best scenery can only be
          accessed by boat. A paddleboat tour in Trang An or Tam Coc is an
          essential Ninh Binh experience. We had two boats for us. The boatmen
          and the boatwoman started to paddle our boats along the gentle
          current. The boats glided underneath grottoes along the way. The
          experience was truly blissful. Smiley was telling how in past the
          poets used to come here and sit peacefully in these silent hills and
          write poems. She also told us about the soul seekers who used to come
          here to meditate. She was right, this place made me very meditative!!
        </p>
        <video controls autoPlay muted preload="auto" src="/blogs/blog-10-7.mp4" type="video/mp4" className="vietnam-video"></video>
        <p className="list-style mt-3">
          We stopped over at a Bamboo market. This is a place where everything
          is made out of Bamboo. T Shirts, cups, clothing materials, toys,
          curios, chappals, the list goes never ending. Amazing to see these
          people use Bamboo and create such products.
        </p>
        <video controls autoPlay muted preload="auto" src="/blogs/blog-10-8.mp4" type="video/mp4" className="vietnam-video"></video>
        <p className="list-style mt-3">
          It took us three hours to reach Hanoi airport. I had slept for most of
          the time. Hanoi airport is an extremely busy airport with tourists
          from every where across the World were busy trying to ﬂy in and out.
          Luckily for us Vietnam airlines put us on an early ﬂight to Danang. We
          bid good bye to Hanoi with very fond memories.
        </p>
        <Link to="/packages/vietnam">
          <h3 className="package-head text-center mt-5">
            ‘THIS IS YOUR LAST CHANCE TO SEE HANOI, DON’T YOU WANT TO GO NOW?’
          </h3>
        </Link>
        <p className="list-style mt-5">
          We landed after one hour in Danang. The weather had changed for good.
          This place has a similar temparature as the early Summer days of
          Bangalore. Our guide Travis was there to receive us with a smile, very
          few people carry. We walked to the car to be received by our driver
          who has a smile which could beat Travis’s hollow!! It was so nice to
          meet these happy people. Was this happiness among these people bcos of
          tourism, was it bcos of the so called, ‘required’ hospitality, or was
          it the true inner ‘Soul’? I did vote for the third!!
        </p>
        <p className="list-style">
          ‘Maazi’ was the Indian restaurant where we picked up our food. I saw
          their menu and found that they had Masala Dosa, Coconut rice, Dahi (
          Curd ) chawal and many more. I picked the Coconut rice and Dahi chawal
          while Mano settled for some chicken. Believe me, I haven’t tasted such
          coconut rice even in the temple town of Kumbakonam!!
        </p>
        <p className="list-style">
          Our hotel was just a street away from the beach. Danang, I would say
          is a slightly colder version of Goa with life like in LA!! Shacks on
          the beach were playing loud music, parties were happening every where,
          the waves went about crashing on to the the most lit beach, making it
          a very good spot for taking selﬁes and photos. The walk on the beach
          after dinner with the cool sea breeze was so blissful. Was it the
          taste of the food or was it the quantity of the food, or maybe both, I
          crashed out like a rock after returning to our hotel after an hour on
          the beach.
        </p>
        <video controls autoPlay muted preload="auto" src="/blogs/blog-10-9.mp4" type="video/mp4" className="vietnam-video"></video>
        <p className="list-style mt-5">
          I woke up early, and headed to the beach again for a walk. The scene
          had changed. The ﬁtness fanatics, Yoga practitioners, the foot ball
          playing kids and some old people ﬁlled the beach. ‘Was this the beach
          I saw last night!!??’
        </p>
        <p className="list-style">
          The hotel had a good spread of breakfast. Post breakfast, I got busy
          with some pending work. We had a half day city tour. I requested
          Travis to pick up us at 9AM. The weather was warm, we started our
          journey towards the Hoi An town. Enroute we saw this lone person
          sitting in a secluded spot, ﬁshing. The place was so beautiful, that I
          requested the driver to stop. A water body surrounded by hills is
          always a perfect place to sit and write a book. But this person was
          trying to catch some ﬁsh. We got down and Mano also tried ﬁshing but
          the bait went missing always!! After some photo sessions, we continued
          our journey. We reached Cam Thanh village after an hour. Travis was
          busy explaining about the culture and history while our stomachs were
          craving for food.
        </p>
        <p className="list-style">
          Cam Thanh village is a peaceful ﬁshing village with canals like what
          you ﬁnd in Kerala. The serene water ways bordered by the arching
          coconut trees, the cool breeze is sure to drop anyones blood pressure
          and calm you down. We got on to a round boat, similar to a coracle
          found in India. The lady started to row. We passed through some very
          nice lagoons, and got to the open Sea. The scene changed!! Some
          boatmen were displaying their technique of dancing on the boat, and
          rocking them on water. People were dancing on a sort of wooden planks
          with some great music. From a classical music to electrifying rock
          music in just under ten minutes!! Every where you could hear music,
          every boatman was dancing and asking the guests to sing along. The
          place was so vibrant. In the middle of this chaos, the local ﬁshermen
          were throwing nets and doing their job. I had some great opportunities
          to take some pictures. The way back was again through the narrow
          channel. Our boatwoman suddenly got a little excited and without
          telling us started to row in a way that the our boat started to spin
          360° and fast. Phew, this wasn’t expected form a 70 year old. But it
          was fun. We tipped her some Vietnam Dong and bid good bye to this
          place.
        </p>
        <video controls autoPlay muted preload="auto" src="/blogs/blog-10-10.mp4" type="video/mp4" className="vietnam-video"></video>
        <Link to="/packages/vietnam">
          {" "}
          <h3 className="package-head text-center mt-5">
            ‘DO YOU WANT TO ENJOY THE CORACLE RIDE IN CAM THANH?’
          </h3>
        </Link>
        <p className="list-style mt-5">
          Our next stop was Hoi An town. After an hour’s drive we reached there.
          We were guided to an Indian restaurant to have some great food. It was
          still very bright. Hoi An town is again a very ancient town in Hanoi
          where the old traditions and culture is still preserved and practised
          till date. There is inﬂuence of the Japanese culture in this town.
          This town was a small-scale trading port which was active from the
          15th to 19th Century. The countries of South East and East Asian
          countries traded a lot with the rest of the World and this town played
          a major part as a port. The Thu Bon river which cuts across this town
          was the main transport route used by the ancient people. The backyards
          of all the houses opened up to the river making loading and unloading
          goods very easy.
        </p>
        <p className="list-style">
          The Myth says that there once lived a very huge dragon with its head
          in India, body in Vietnam and tail in Cambodia. The Japanese believed
          that the heart of the dragon was at Hoi An. So when they built a
          bridge, they drove down the pillars like knives into the dragon’s
          heart to keep it quiet and the World was at peace. Now this old bridge
          is undergoing renovation.
        </p>
        <p className="list-style">
          As the evening approached the lanterns in this town started to light
          up. This slight was truly a treat to my eyes. To see so much of
          colours was very similar to witnessing Diwali festival in a crowded
          North India street without the sound of crackers!! The narrow canoes
          with lanterns started plying on the river with the tourists. This was
          another sight which I will never forget in this life time.
        </p>
        <video controls autoPlay muted preload="auto" src="/blogs/blog-10-11.mp4" type="video/MP4" className="vietnam-video"></video>
        <Link to="/packages/vietnam">
          <h3 className="package-head text-center mt-5">
            ‘DO YOU WANT TO VIST ONE OF THE MOST COLOURFUL PLACE ON EARTH?’
          </h3>
        </Link>
        <p className="list-style mt-5">
          We got back to Danang, had dinner and slept. Travis had told us to be
          ready very early the next morning cos we had to go to the Ba Na hills.
          Though it was only a hours’ drive from our hotel, the idea was to
          spend quality time at this beautiful location. The parking space at Ba
          Na hills is bigger than two football grounds. This clearly meant that
          this was also one of the most famous destinations in Vietnam. Travis
          told us that this place attracted more than 10,000 tourists every day.
          And during weekends the crowd doubles!! Hmm, so we are looking at
          close to 40 lakh people every year. That’s mind blowing, isn’t it?
        </p>
        <p className="list-style">
          Ba Na hills was the place the French colonists built for French people
          to spend Liesure time. They had built a huge fortress with beautiful
          gardens. The colonial government spent a huge money to turn this place
          into a Popular leisure destination. After the French left Vietnam,
          this place was forgotten. But a decade ago the Vietnamese Govt
          awakened this sleeping beauty. This place now is a hill station that
          houses many world-class resorts, amusement park and many awesome
          manmade attractions such as French architecture buildings, Flower
          Garden and, of course, the Golden Bridge - a worldwide phenomenon.
          According to local sources and what. Travis told us, the name Ba Na
          meant ‘My Mountain’. But the funnier version is people started to call
          this mountain by this name bcos this place is full lot of Banana
          plants and the French word for this is ‘Banane’, which the Vietnamese
          rendered to Ba Na.
        </p>
        <p className="list-style">
          To reach on top, one has to go only by a cable car. This is the
          longest cable car route in the whole of Asia. This place was so
          crowded that Travis pulled out his extendable stick which had a cute
          doll ﬁxed to it. He always carried this long stick over his shoulder
          for us to locate him in the crowd. Ba Na hills has two parts. One is
          where you and the beautiful fortress of the French, and the other is
          the iconic Golden hand bridge. These two places have to be reached
          only by cable cars. The cable car ride was such a wonderful
          experience. The cascades along the mountains, the lush green forests
          below, the magical mist above and cool breeze ﬂowing inside the cable
          car made me feel that I was watching the movie Avatar in a 3D version.
          This place is so mystical and magical. I never felt that I was on
          Earth anymore. I really experienced heaven here. With enough and more
          pictures clicked , we headed for a much needed hot lunch. The
          Bharatiya restaurant here can hosts 2000 guests at any given time. It
          was huge the buﬀet spread was very big. After a sumptuous meal, we
          returned to base camp.
        </p>
        <video controls autoPlay muted preload="auto" src="/blogs/blog-10-12.mp4" type="video/mp4" className="vietnam-video"></video>
        <Link to="/packages/vietnam">
          <h3 className="package-head text-center mt-5">
            ‘DO YOU WANT TO VIST ONE OF THE HEAVEN ON EARTH - BA NA HILLS?’
          </h3>
        </Link>
        <p className="list-style mt-5">
          I had been walking the entire day. I had to get a massage. There are
          so may massage parlours at Danang. I was highly recommended to get my
          massage done at one parlour, which I went to. I really don’t know when
          I slept and when I woke up. Every muscle of my body was smiling after
          the massage. It was time for dinner and sleep.
        </p>
        <p className="list-style">
          We had a very early morning ﬂight to go to Ho Chi Minh. Travis and the
          driver were there on time and dropped us to the Danang airport. The
          airport wasn’t that crowded as Hanoi and it is very well designed to
          take a lot of tourists and travellers. We bid good bye to one of the
          most lively place on Earth and two of the happiest souls I had met in
          life and boarded the ﬂight to Ho Chi Minh.
        </p>
        <img src="/blogs/blog-10-13.jpg" alt="" className="blog-image" />
        <Link to="/packages/vietnam">
          <h3 className="package-head text-center mt-5">
            ‘THIS IS YOUR LAST CHANCE TO VISIT DANANG, DON’T YOU WANT TO GO
            NOW?’
          </h3>
        </Link>
        <p className="list-style mt-5">
          We reached Ho Chi Minh around 10AM. Trong was our guide for this
          economic capital city of Vietnam. Our check in time was only at 2PM.
          We could have opted for a later ﬂight from Danang, but Trong had his
          plans laid out for us. We had a half day City tour set for us. I
          really loved the way our local partner curates the itineraries. They
          have always ensured that the guests are always engaged and they do not
          waste any time. And this is one reason why all our guests who have
          travelled to Vietnam with us have returned with life time memories and
          have been referring their friends and families to us.
        </p>
        <p className="list-style">
          The ﬁrst step was at the Independence palace. Vietnam after French
          walked out gt divided into two parts. The North Vietnam which was
          supported by the Soviet Union and China and the South Vietnam which
          was supported by the US. The Independence palace was the oﬃce of the
          leader of South Vietnam. This is where he held his oﬃce with his
          generals. He also made it is home with escape chambers incase of
          attack from the enemies. This place has some very interesting stories.
          The ﬁrst leader of South Vietnam held his place for a long time, but
          the last one held his position only for two days before he surrendered
          to the North Vietnamese soldiers. Trong explained the entire history
          of this place so nicely. Listening to him was like reading an
          encyclopaedia and wikipedia together. I was so happy with the quality
          of our guides at all locations. No wonder we have so many guests
          travelling with us and recommending their friends to us!!
        </p>
        <p className="list-style">
          We walked around the palace and learnt so much of Vietnamese history.
          This country’s history closely resembled the Indian history of the
          Kings who fought among themselves and the ﬁght for Independence. The
          furnitures are still intact and one gets the feel that still the old
          leaders are functioning from this palace.
        </p>
        <img src="/blogs/blog-10-14.jpg" alt="" className="blog-image" />
        <p className="list-style mt-3">
          I would want to take some time oﬀ to explain about the public toilets
          of Vietnam. Every tourists place I visited, had one of the cleanest
          toilets. They were so dry and very very high quality brand of closets
          like Toto are being used across. With thousands of tourists visiting
          everyday, I was just amazed the way they have been maintaining them.
          Absolutely no stink or smell, the toilets are exactly like ﬁve star
          hotel bathrooms at the time of check in!! Not only this makes you
          enjoy your entire tour but also makes you learn that we are so behind
          these small countries in hygiene and cleanliness.
        </p>
        <p className="list-style">
          Just across the palace and very close by is the Ho Chi Minh post oﬃce
          and the Notre Dame Cathedral. The Cathedral was under renovation, so
          we could not go inside. But we got to explore the head post oﬃce which
          was built during the olden days but still functions. People still use
          it to send mails and parcels. This place is also a tourists attraction
          with small shops selling beautiful curios and statues of Vietnamese
          women wearing traditional clothes and the famous bamboo hat.
        </p>
        <Link to="/packages/vietnam">
          <h3 className="package-head text-center mt-5">
            ‘DO YOU WANT TO EXPLORE HO CHI MINH CITY?’
          </h3>
        </Link>
        <p className="list-style mt-5">
          Post lunch, we headed to our hotel and checked in. It was Liesure time
          for us in the evening. Our hotel was right in the middle of this
          buzzing city. The skyscrapers, the cars in this City made me think
          that I was in one of the very well developed Gulf countries or in
          Singapore. I just couldn’t believe the way this City has been built.
          If someone says Vietnam is a poor country, I don’t how I would react
          and what would be my reply to them!! I would say that this place is
          one of the most aﬀordable, but very well built and kept up places on
          Earth. It is a must see place in ones life time. To miss going to
          Vietnam would be truly a sin!!
        </p>
        <p className="list-style">
          Our hotel bordered the walking street. This broad street has a very
          colourful, lit dragon structure running across the length of the road.
          To my surprise, there was also a huge stage of 100feet in length built
          at one corner of this street with laser lights and high quality sound
          system for performers. I had to pinch myself to realise that this
          wasn’t a dream.
        </p>
        <p className="list-style">
          The road is full of performers. People with Pythons, Boas trying to
          make tourists put the snakes around their necks and pose for pictures,
          small time vendors selling kites, roadside shops serving hot food,
          people singing with Karaokes, tourists dancing to the tunes, kids
          playing around with toys, the selﬁe professionals, made this one of
          the busiest and one of the most vibrant streets I have ever walked on.
          Located on the end of the street you ﬁnd the People’s Committee
          Building, a prominent architectural gem. It serves as the headquarters
          of Ho Chi Minh City’s government. Its grandeur and historical
          signiﬁcance make it a notable sight to admire while strolling along
          the street. Life never seems to end on this street. I could hear the
          performance till 3AM while on was on my bed sleeping.
        </p>
        <video controls autoPlay muted preload="auto" src="/blogs/blog-10-15.mp4" type="video/mp4" className="vietnam-video"></video>
        <p className="list-style mt-3">
          We had a very early breakfast and left our hotel by 7AM bcos we had a
          very long day and we had to take our ﬂight back to India late in the
          night. Trong was there at the lobby waiting for us with his favourite
          cup of coﬀee. We headed to the Chu Chi tunnels. The drive took a
          little more than a hour to reach this place. Trong had carried the old
          maps and explained the entire history of this place during the drive.
          This was truly appreciable cos helper us so engaged and ensured he did
          not waste time when we reached the place
        </p>
        <p className="list-style">
          Chu Chi tunnels were built by the North Vietnamese. This place is just
          70 kms from the City centre. This gigantic underground tunnel system,
          was originally constructed under the jungle terrain, connecting
          tunnels among the hamlets and communes during the Indochina war
          (1945-1954) against the French colonialists. These tunnels played a
          pivotal role in the Vietnamese forces' resistance to the American army
          in South Vietnam. Stretching to the Cambodian frontier, the system of
          Cu Chi tunnels used to reach a total length of nearly 250 kilometers,
          of which 120 kilometers have been well-preserved and available for
          public visit nowadays. The complex of Cu Chi Tunnels in Saigon
          astounds visitors with its intricate architecture and resilience in
          the area of clay soil mixed with laterite. The booby traps were laid
          on top and the soldiers had a very special way of marking these traps
          with a fallen leaf with a small twig pocked into it. For a new comer
          this was just another fallen leaf, but once they foot on this, it was
          their end.
        </p>
        <p className="list-style">
          The tunnels has three ﬂoors. The tourists are allowed to access only
          two levels. The ﬁrst ones are the large bunkers, kitchen with
          specially designed air vents, meeting and documentaries rooms. These
          are four feet below the ground level. But when you get down another
          two metres below, things change. The narrow tunnels with very small
          air vents which you can’t see, the dark long passages where you have
          to crawl makes you feel so suﬀocated and claustrophobic. I followed
          Trong who was crawling into these tunnels like his forefathers did. I
          was gasping for my breath and struggling to hold my Go pro. This
          experience is not for someone who is even slightly fat or anyone who
          is faint hearted. The two and half minutes I spend underground was too
          much of an experience. And when I saw the opening, I thanked all Gods
          up heaven. When I came out, my heart went to all the braving soldiers
          who survived in these tunnels with enemies bombing and poisoning every
          area above them. Their strength in Unity made them win over the US
          soldiers, who at time did not have clue of the Gorilla warfare.
        </p>
        <video controls autoPlay muted preload="auto" src="/blogs/blog-10-16.mp4" type="video/mp4" className="vietnam-video"></video>
        <Link to="/packages/vietnam">
          <h3 className="package-head text-center mt-5">
            ‘DO YOU WANT TO BRAVELY GO INSIDE THE CU CHI TUNNELS?’
          </h3>
        </Link>
        <p className="list-style mt-5">
          From here we again jumped into our cab and drove to Mekong delta. The
          Mekong river is one of the longest rivers of the World. The river has
          a length of approximately 4,900 km, ﬂowing from its source on the
          Tibetan Plateau in China through Myanmar, Lao PDR, Thailand, Cambodia
          and Viet Nam via a large delta into the sea. And this river like
          Mother Ganges is a life giver for all its dwellers on her banks. In
          fact Mekong also means, ‘Mother of Water’.
        </p>
        <p className="list-style">
          We got into a motor boat which took us to the other bank of the river
          to a small village. Another surprise for me, we had an Indian
          restaurant here too!! Rice, Dhal, Sabji, papads, salad, and the list
          goes on. Post lunch, we got on to a electric rickshaw and were taken
          to a little village where we were invited to a tea party. Freshly cut
          fruits were served on a bamboo plater and hot ginger tea was served.
          The locals put up a great performance for us. I have lost the sense of
          time at a few places, I did lose it again here. Things did not end
          here.
        </p>
        <p className="list-style">
          We boarded our canoe to enjoy the tranquil waterways ﬂanked by swaying
          coconut trees. The canoes here are propelled by very old people. There
          are two saddlers, one in the front and one behind. This old lady sat
          in the front and gracefully saddled the canoe. She was a epitome of
          innocence. I could only see smile lines on her face and not even one
          worry line. I took some pictures and just immersed in this beautiful
          moment. She turned to Trong who was sitting behind me and told him
          something. After we got down I asked him what did the lady say. He
          replied that he would send me a message of what she told when we reach
          the airport. I knew she had something deep to share. With thoughts
          running, we headed back to our hotel to pack our bags, had a quick
          dinner and got dropped at the airport. After the security check I
          dozed oﬀ on my chair. After a few minutes the boarding started. I got
          to my seat, settled down and fastened my seat belt. As I was trying to
          switch oﬀ my phone, Trong’s message came in,
        </p>
        <video controls autoPlay muted preload="auto" src="/blogs/blog-10-17.mp4" type="video/mp4" className="vietnam-video"></video>
        <p className="list-style mt-3">
          Chandru Sir, this is what the lady said, “ My Son, tell this person
          that there is nothing called past or future, what he is doing now,
          enjoying the present moment is what is called Life”..
        </p>
        <Link to="/packages/vietnam">
          <h3 className="package-head text-center mt-5">
            ‘I’m sure you don’t to miss visiting this Paradise on Earth!! Just
            click here and start your journey!!’
          </h3>
        </Link>
      </div>
      <div className="text-center mt-5 mb-5">
        <Link to="/form">
          <button type="button" className="book-btn">
            Book Now
          </button>
        </Link>
      </div>
    </HelmetProvider>
    </>
  );
};

export default Blog10;
