import React, {useEffect} from "react";
import { Link } from "react-router-dom";
import Navbar from "../Navbar";
import HomeSlider from '../HomeSlider';
import "./Home.css";
import Testimonials from '../Testimonials';
import Footer from "../Footer";
import "bootstrap/dist/css/bootstrap.min.css";
import { HelmetProvider, Helmet } from 'react-helmet-async';

const images = [
  "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d/o/tu-home%2Ftu-home-imgs%2F1-1.avif?alt=media&token=0d459474-e9ba-4064-891f-38b1ecbc2e7f",
  "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d/o/tu-home%2Ftu-home-imgs%2F2.webp?alt=media&token=540af043-c30e-4c4b-ba16-5f6f72437d66",
  "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d/o/tu-home%2Ftu-home-imgs%2F3.avif?alt=media&token=a0d64d40-8153-40b7-9afe-a085679dd69e",
  "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d/o/tu-home%2Ftu-home-imgs%2F4.webp?alt=media&token=5aee9f17-90b5-415b-bef0-3f80331f4260",
  "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d/o/tu-home%2Ftu-home-imgs%2F5.avif?alt=media&token=00ec0b1f-f367-4867-bd0d-509028623c8e",
  "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d/o/tu-home%2Ftu-home-imgs%2F6.webp?alt=media&token=d815f4d5-ec7f-4a1b-8ef3-e95768aa2850",
  "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d/o/tu-home%2Ftu-home-imgs%2F7.avif?alt=media&token=fce64880-cdea-43d1-8efe-bf234140427f",
  "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d/o/tu-home%2Ftu-home-imgs%2F8-1-1.webp?alt=media&token=aec3f00c-e836-494e-8dc7-6f2e2285e28c",
  "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d/o/tu-home%2Ftu-home-imgs%2F9-1.avif?alt=media&token=7e991f82-ce4c-4bbf-bbfe-9beb5a80cf43",
]

const preloadImages = (imageUrls) => {
  imageUrls.forEach((url) => {
    const img = new Image();
    img.src = url;
  });
};

function Home() {
  useEffect(() => {
    preloadImages(images);
  }, []);

  return (
      <div>
        <HelmetProvider>
        <Helmet>
          <title>Travel Unbounded: Unforgettable Global Travel Packages | Adventure Awaits</title>
          <meta
            name="description"
            content="Discover tailored travel packages with Travel Unbounded. Explore thrilling destinations worldwide, from African safaris to tropical getaways. Book your next adventure and unlock exclusive deals on unforgettable experiences today!"
          />
         </Helmet>
      <Navbar />
      <HomeSlider images={images} showOverlayText={true}/>
      <h1 className="home-head text-center mb-5 mt-5">DESTINATIONS</h1>
      <div className="home-container">
        <div className="home-destination-container">
          <div className="right d-md-none">
          <picture>
                <source srcSet="https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/TU-Home%2Fkenya%20wildlife%20safari.jpg?alt=media&token=8c497a90-bfec-45d2-8dc4-d695cda4478c" type="image/avif" />
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/TU-Home%2Fkenya%20wildlife%20safari.jpg?alt=media&token=8c497a90-bfec-45d2-8dc4-d695cda4478c"
                  alt="African-safari-Wildlife-tours-luxery-safari"
                  className="wildlife-img"
                  loading="lazy"
                />
          </picture>
          </div>
          <div className="left mt-3">
            <h1 className="home-destination-name-head">Kenya</h1>
            <div className="home-description-container">
              <p className="home-destination-description">
                Kenya is a country of dramatic extremes. It is one of the most
                diverse places on Earth. It’s open plains and dense forests, the
                snow clad mountains and the deserts, the ancient colourful
                culture and the buzzing modern lives, its fresh water lakes and
                coral reefs together form a landscape never to be seen anywhere
                in the World. Kenya is world famous for its savannah safaris.
                Kenya is referred as the Mecca of Wildlife.
              </p>
            </div>
            <Link to="/kenya" className="custom-btn">
              <div className="home-btn-container">
                <button type="button" id="readMoreBtn">
                Explore Kenya's Wildlife Adventures
                </button>
              </div>
            </Link>
          </div>
          <div className="right d-none d-md-block">
          <picture>
                <source srcSet="https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/TU-Home%2Fkenya%20wildlife%20safari.jpg?alt=media&token=8c497a90-bfec-45d2-8dc4-d695cda4478c" type="image/avif" />
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/TU-Home%2Fkenya%20wildlife%20safari.jpg?alt=media&token=8c497a90-bfec-45d2-8dc4-d695cda4478c"
                  alt="African-safari-Wildlife-tours-luxery-safari"
                  className="wildlife-img"
                  loading="lazy"
                />
          </picture>
          </div>
        </div>
        <div className="home-destination-container">
          <div className="left">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d/o/tu-home%2Fimg-13-4.jpg?alt=media&token=9bfbf761-512a-4625-a1fb-ebde071136e8"
              alt="African-safari-Wildlife-tours-luxery-safari"
              className="wildlife-img"
              loading="lazy"
            />
          </div>
          <div className="right">
            <h1 className="home-destination-name-head mt-3">Vietnam </h1>
            <div className="home-description-container">
              <p className="home-destination-description">
              With its very long history, culture and breathtaking landscapes Vietnam today os one of the most
              popular holiday destinations of the World. Vietnam is one lace which can be visited all around the
              year. The weather across the country is suitable for various seasons. It is also one of the most
              tourists friendly places and very affordable compared to a lot of other countries in the World. It is
              one of the finest holiday destinations for friends and families. It offers different activities for different
              age groups.
              </p>
            </div>
            <Link to="/vietnam" className="custom-btn">
              <div className="home-btn-container">
                <button type="button" id="readMoreBtn">
                Discover the Beauty of Vietnam
                </button>
              </div>
            </Link>
          </div>
        </div>
        <div className="home-destination-container">
          <div className="left">
            <div className="right d-md-none">
            <picture>
                <source srcSet="https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d/o/tu-home%2Ftu-home-imgs%2Ftanzania.avif?alt=media&token=dea821f8-7080-403b-ab6d-4eed631aa15d" type="image/avif" />
                <source srcSet="https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d/o/tu-home%2Ftu-home-imgs%2Ftanzania.webp?alt=media&token=5d04536c-6204-475b-9344-cb1367506363" type="image/webp" />
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/TU-Home%2Ftanzania.jpg?alt=media&token=4a196155-9977-4732-af4d-38120f98cd17"
                  alt="African-safari-Wildlife-tours-luxery-safari"
                  className="wildlife-img"
                  loading="lazy"
                />
            </picture>
            </div>
            <h1 className="home-destination-name-head mt-3">Tanzania</h1>
            <div className="home-description-container">
              <p className="home-destination-description">
                Known for it's vast area of wilderness , it is one of the most
                sought out wildlife destination in the World. It is where you
                can see the highest mountain of Africa, Mount Kilimanjaro. It is
                famously known as the Land Of Safaris. Millions of wildebeests
                and other ungulates traverse this land during their great
                migration. They cross from here to Maasai Mara every year. This
                is called The Great Migration and during the month of Febraury
                on the plains of Ndutu, these animals give birth to millions of
                calves. It is a truly a sight to watch.
              </p>
            </div>
            <Link to="/tanzania" className="custom-btn">
              <div className="home-btn-container">
                <button type="button" id="readMoreBtn">
                Experience the Serengeti in Tanzania
                </button>
              </div>
            </Link>
          </div>
          <div className="right d-none d-md-block">
          <picture>
                <source srcSet="https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d/o/tu-home%2Ftu-home-imgs%2Ftanzania.avif?alt=media&token=dea821f8-7080-403b-ab6d-4eed631aa15d" type="image/avif" />
                <source srcSet="https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d/o/tu-home%2Ftu-home-imgs%2Ftanzania.webp?alt=media&token=5d04536c-6204-475b-9344-cb1367506363" type="image/webp" />
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/TU-Home%2Ftanzania.jpg?alt=media&token=4a196155-9977-4732-af4d-38120f98cd17"
                  alt="African-safari-Wildlife-tours-luxery-safari"
                  className="wildlife-img"
                  loading="lazy"
                />
          </picture>
          </div>
        </div>
        <div className="home-destination-container">
          <div className="left">
              <img
                src="https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/TU-Home%2FTiger-Bigcats-Wildsafari.jpg?alt=media&token=00ec7ab2-683d-4b9d-a331-30eccd0653e0"
                alt="African-safari-Wildlife-tours-luxery-safari"
                className="wildlife-img"
                loading="lazy"
              />
          </div>
          <div className="right">
            <h1 className="home-destination-name-head mt-3"> Ranthambore </h1>
            <div className="home-description-container">
              <p className="home-destination-description">
                Famously known as the land of the Royal Bengals, this place has
                the oldest history of man animal co existence. Ranthambore
                National Park is one of the biggest and most renowned national
                park in Northern India. The park is located in the Sawai
                Madhopur district of southeastern Rajasthan, which is about 130
                km from Jaipur. Being considered as one of the famous and former
                hunting grounds of the Maharajas of Jaipur, today the
                Ranthambore National Park terrain is major wildlife tourist
                attraction spot that has pulled the attention of many wildlife
                photographers and Nature lovers to this destination.
              </p>
            </div>
            <Link to="/ranthambore" className="custom-btn">
              <div className="home-btn-container">
                <button type="button" id="readMoreBtn">
                Safari in Ranthambore National Park
                </button>
              </div>
            </Link>
          </div>
        </div>

        <div className="home-destination-container">
          <div className="left">
            <div className="right d-md-none">
            <picture>
                <source srcSet="https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d/o/tu-home%2Ftu-home-imgs%2Fbandhavgarh.avif?alt=media&token=d4ac7304-ed95-494f-bebd-73aeaad341de" type="image/avif" />
                <source srcSet="https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d/o/tu-home%2Ftu-home-imgs%2Fbandhavgarh.webp?alt=media&token=8aaa3bad-4d37-4832-9c55-1386b46c9883" type="image/webp" />
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/TU-Home%2Fbandhavgarh.jpg?alt=media&token=3ae6cbfb-061c-4775-9a61-2e640ec5ec0a"
                  alt="best-safaris-in-africa-eco-friendly-luxery-travel"
                  className="wildlife-img"
                  loading="lazy"
                />
            </picture>
            </div>
            <h1 className="home-destination-name-head mt-3"> Bandhavgarh </h1>
            <div className="home-description-container">
              <p className="home-destination-description">
                Ever wondered doing jungle drives in the &quot;Tiger Capital of
                the World&quot;? We make that possible now and do it with taking
                care of your comfort both on and off the field. Sighting here is
                high and regular when compared to any other Tiger reserve in
                India and therefore a jungle safari here makes perfect sense.
                With Tigers in all the zones, it is going to be lifetime
                experience in the jungle.
              </p>
            </div>
            <Link to="/bandhavgarh" className="custom-btn">
              <div className="home-btn-container">
                <button type="button" id="readMoreBtn">
                Explore Bandhavgarh's Tiger Reserve
                </button>
              </div>
            </Link>
          </div>
          <div className="right d-none d-md-block">
          <picture>
            <source srcSet="https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d/o/tu-home%2Ftu-home-imgs%2Fbandhavgarh.avif?alt=media&token=d4ac7304-ed95-494f-bebd-73aeaad341de" type="image/avif" />
            <source srcSet="https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d/o/tu-home%2Ftu-home-imgs%2Fbandhavgarh.webp?alt=media&token=8aaa3bad-4d37-4832-9c55-1386b46c9883" type="image/webp" />
              <img
                src="https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/TU-Home%2Fbandhavgarh.jpg?alt=media&token=3ae6cbfb-061c-4775-9a61-2e640ec5ec0a"
                alt="best-safaris-in-africa-eco-friendly-luxery-travel"
                className="wildlife-img"
                loading="lazy"
              />
          </picture>
          </div>
        </div>
        <div className="home-destination-container">
          <div className="left">
              <img
                  src="https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/TU-Home%2FTuskers%20from%20kenya-Wildlife%20safari%20(1%20of%201).jpg?alt=media&token=37f2fcf2-0b00-429a-a305-bc27c00155c8"
                  alt="best-safaris-in-africa-eco-friendly-luxery-travel"
                  className="wildlife-img"
                  loading="lazy"
              />
          </div>
          <div className="right">
            <h1 className="home-destination-name-head mt-3"> Corbett </h1>
            <div className="home-description-container">
              <p className="home-destination-description">
                Named after the famed hunter turned conservationist , Sir Edward
                Jim Corbett, this beautiful park lies in the lower Himalayas.
                The life giving Ramganga river cuts through this pristine
                jungle. Corbett Tiger Reserve is home to the magnificent Royal
                Bengal Tiger. It is also home for the Asiatic elephants which
                come down to the Dhikala grass lands during summer in hundreds
                of numbers. One can sight spotted deers, sambhars and the
                elusive hog deer. This is one of the best parks to enjoy the
                Indian wildlife.
              </p>
            </div>
            <Link to="/corbett" className="custom-btn">
              <div className="home-btn-container">
                <button type="button" id="readMoreBtn">
                Visit Jim Corbett National Park
                </button>
              </div>
            </Link>
          </div>
        </div>
        <div className="home-destination-container">
          <div className="left">
            <div className="right d-md-none">
            <picture>
                <source srcSet="https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d/o/tu-home%2Ftu-home-imgs%2Fbandipur.avif?alt=media&token=74c413b9-d503-4180-b4b7-b57f5ac3c2f4" type="image/avif" />
                <source srcSet="https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d/o/tu-home%2Ftu-home-imgs%2Fbandipur.webp?alt=media&token=dbad1905-897f-4166-b7df-fac1f69d31dc" type="image/webp" />
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/TU-Home%2Fbandipur.jpg?alt=media&token=3d429681-ff8c-4f3e-8590-9d3c4c89ab4d"
                  alt="best-safaris-in-africa-eco-friendly-luxery-travel"
                  className="wildlife-img"
                  loading="lazy"
                />
            </picture>
            </div>
            <h1 className="home-destination-name-head mt-3"> Bandipur </h1>
            <div className="home-description-container">
              <p className="home-destination-description">
                The patch of dry deciduous forests lies enroute to the famous
                Ooty hills and Karnataka. The forest is divided by the National
                Highway which cuts through the dense jungle. The Bandipur forest
                is home for the tiger, leopard, wild dogs, Indian gaur, Asiatic
                elephants, spotted deers, Sambhar deers and other mammals.
              </p>
            </div>
            <Link to="/bandipur" className="custom-btn">
              <div className="home-btn-container">
                <button type="button" id="readMoreBtn">
                Adventure in Bandipur National Park
                </button>
              </div>
            </Link>
          </div>
          <div className="right d-none d-md-block">
          <picture>
            <source srcSet="https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d/o/tu-home%2Ftu-home-imgs%2Fbandipur.avif?alt=media&token=74c413b9-d503-4180-b4b7-b57f5ac3c2f4" type="image/avif" />
            <source srcSet="https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d/o/tu-home%2Ftu-home-imgs%2Fbandipur.webp?alt=media&token=dbad1905-897f-4166-b7df-fac1f69d31dc" type="image/webp" />
              <img
                src="https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/TU-Home%2Fbandipur.jpg?alt=media&token=3d429681-ff8c-4f3e-8590-9d3c4c89ab4d"
                alt="best-safaris-in-africa-eco-friendly-luxery-travel"
                className="wildlife-img"
                loading="lazy"
                />
          </picture>
          </div>
        </div>
        <div className="home-destination-container">
          <div className="left">
          <picture>
            <source srcSet="https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d/o/tu-home%2Ftu-home-imgs%2Fkabini.avif?alt=media&token=b7dcceea-7e17-4941-9827-452210c29d2b" type="image/avif" />
            <source srcSet="https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d/o/tu-home%2Ftu-home-imgs%2Fkabini.webp?alt=media&token=08afc789-466c-4750-9bce-1252391377f2" type="image/webp" />
              <img
                src="https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/TU-Home%2Fkabini.jpg?alt=media&token=8b09d941-7996-43f7-a13a-d1621aa57214"
                alt="best-safaris-in-africa-eco-friendly-luxery-travel"
                className="wildlife-img"
                loading="lazy"
              />
          </picture>
          </div>
          <div className="right">
            <h1 className="home-destination-name-head mt-3"> Kabini </h1>
            <div className="home-description-container">
              <p className="home-destination-description">
                Kabini is one place where the top three predators of the
                subcontinent are found. The tiger, the leopard and the wild dogs
                roam these forests. The forests teems with spotted deers,
                sambhar deers, great Indian squirrel, Indian gaur ( Bison ) and
                the elusive musk deer. Birdlife is very good at Kabini.
              </p>
            </div>
            <Link to="/kabini" className="custom-btn">
              <div className="home-btn-container">
                <button type="button" id="readMoreBtn">
                Wildlife Safari in Kabini
                </button>
              </div>
            </Link>
          </div>
        </div>
        <div className="home-view-package-btn-container">
          <Link to="/packages" className="">
            <button type="button" id="readMoreBtn" className="home-view-package-btn">
              View Packages
            </button>
          </Link>
        </div>
        <Testimonials/>
        <Footer />
      </div>
      </HelmetProvider>
    </div>
  );
}
export default Home;
