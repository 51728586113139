import React from "react";
import { Link } from "react-router-dom";
import "./card.css";
import { useEffect, useState } from "react";
import Loader from "react-loading";
import axios from "axios";

const DestinationCard = () => {
  const [loading, setLoading] = useState(true);
  const [cards, setCards] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://formbackend.netlify.app/api/south-africa-cards"
        );
        setCards(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching card items:", error);
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  return (
    <>
      <div className="destination-card">
      {loading ? (
          <Loader type="balls" color="blue" height={40} width={40} style={{ 
            display: 'block', 
            margin: '0 auto', 
            position: 'absolute', 
            top: '50%', 
            left: '50%', 
            transform: 'translate(-50%, -50%)' 
          }}  />
        ) : (
          <div className="package-container">
            {cards.map((card) => (
              <div key={card.id} className="package-list-card mb-3">
                <img src={card.image} alt="African-safari-wildlife-tours-luxury-safari" className="list-card-img mb-3" />
                <div>
                  <h4 className="title-list mb-3">{card.name}</h4>
                  <h5 className="title-rate-list bold mb-3">{card.days}</h5>
                  <h5 className="title-rate-list bold mb-3">{card.price}</h5>
                  <div className="btn-container-list-card">
                    <Link to={card.link} className="blogs-link">
                      <button className="view-button-list-card">
                        View Itinerary
                      </button>
                    </Link>
                    <Link to="/form" className="blogs-link">
                      <button className="view-button-list-card-book">
                        Book Now
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
          )}
      </div>
    </>
  );
};

export default DestinationCard;
