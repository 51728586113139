import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import Navbar from '../Navbar';
import { MdLocalAirport, MdDirectionsRailway } from "react-icons/md";
import { FaRegCalendarAlt } from "react-icons/fa";
import { GiDuration } from "react-icons/gi";
//import 'react-vertical-timeline-component/style.min.css';
//import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import './index.css';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import { FaPlus } from "react-icons/fa6";
import { FaMinus } from "react-icons/fa6";
import { FaWhatsapp, FaInstagram} from "react-icons/fa";
import { FaPhoneVolume } from "react-icons/fa6";
import { FaYoutube } from "react-icons/fa";
import {
  AiOutlineMail,
  AiOutlineLinkedin,
  AiOutlineFacebook,
  AiOutlineCopyrightCircle
} from "react-icons/ai";

const firebaseConfig = {
    apiKey: "AIzaSyB4BPMHKw0OXM2PhQX9NcT25lDBWpRpIPQ",
    authDomain: "phone-auth-d0a8d.firebaseapp.com",
    projectId: "phone-auth-d0a8d",
    storageBucket: "phone-auth-d0a8d.appspot.com",
    messagingSenderId: "309252587015",
    appId: "1:309252587015:web:3f9dc2505965e66269b4a2"
};

if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
}

const PackageDetails = () => {
  const { destinationName } = useParams();
  const formattedDestination = destinationName.replace(/-/g, ' ');
  const [packageData, setPackageData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [activeIndex, setActiveIndex] = useState(0);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  useEffect(() => {
    const fetchPackage = async () => {
      const db = firebase.firestore();
      try {
        const docRef = db.collection('PackageTemplate').doc(formattedDestination);
        const doc = await docRef.get();
        if (doc.exists) {
          setPackageData(doc.data());
        } else {
          console.warn("No package found");
        }
      } catch (error) {
        console.error("Error fetching package:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchPackage();
  }, [formattedDestination]);

  if (isLoading) {
    return(
      <div className='loading-container'>
      <p className='loading'></p>
      </div>
    )
  }

  if (!packageData) {
    return <p>No package details available.</p>;
  }

  const {
    title = "",
    description = "",
    destinationHead = "",
    noOfDays = "",
    travelDates = "",
    isForeign = false,
    aboutDestination = [],
    cost = "",
    bestTimeToTravel = "",
    nearestAirport = "",
    nearestRailwayStation = "",
    duration = "",
    contact = "",
    email = "",
    shortItinerary = [],
    itineraryTitles = [],
    inclusions = [],
    exclusions = [],
    paymentTerms = [],
    cancellationTerms = [],
    bannerImageUrl = ""
  } = packageData;

  const renderTravelDetails = () => {
    if (isForeign) {
      return (
        <>
          <div className="icon-card-foreign">
            <FaRegCalendarAlt size={40} className='icon-class'/>
            <p><strong>Best Time to Travel</strong></p>
            <p>{bestTimeToTravel}</p>
          </div>
          <div className="icon-card-foreign">
            <MdLocalAirport size={40} className='icon-class'/>
            <p><strong>International Airport</strong></p>
            <p>{nearestAirport}</p>
          </div>
          
        </>
      );
    } else {
      return (
        <>
          <div className="icon-card">
            <FaRegCalendarAlt size={40} className='icon-class'/>
            <p><strong>Best Time to Travel</strong></p>
            <p>{bestTimeToTravel}</p>
          </div>
          <div className="icon-card">
            <MdLocalAirport size={40} className='icon-class'/>
            <p><strong>Nearest Airport</strong></p>
            <p>{nearestAirport}</p>
          </div>
          <div className="icon-card">
            <MdDirectionsRailway size={40} className='icon-class'/>
            <p><strong>Nearest Railway Station</strong></p>
            <p>{nearestRailwayStation}</p>
          </div>
          <div className="icon-card">
            <GiDuration size={40} className='icon-class'/>
            <p><strong>Duration</strong></p>
            <p>{duration}</p>
          </div>
        </>
      );
    }
  };

  return (
    <>
    <HelmetProvider>
    <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
      </Helmet>
      <Navbar/>
      <div className='package-banner-container'>
        {bannerImageUrl && (
          <img
            src={bannerImageUrl}
            alt={destinationName}
            className='package-img'
          />
        )}
      </div>
      <div className='package-container'>
      <div className='container mt-4'>
        <h3 className='mt-3 justify' style={{color: '#8B4513', fontWeight:'bold'}}>{destinationHead}</h3>
        <p className='mt-3' style={{color: '#8B4513'}}>{noOfDays}</p>
        <div className='mt-3 justify'><span className='overview-head'>Overview</span> <br/>{aboutDestination.map((paragraph, index) => ( <p key={index}>{paragraph}</p> ))}</div>
        <h5 className='mt-3'><b>Price : {cost}</b></h5>
        <p className='mb-5'><b>{travelDates}</b></p>
        <div className="icon-card-container mb-5">
            {renderTravelDetails()}
          </div>  
        <h1 className='mt-3 mb-3 text-center' style={{color: '#8B4513', fontWeight:'bold'}}>Short Itinerary</h1>
            <div className="itinerary-container mb-5">
              {shortItinerary.map((item, index) => (
                <div
                  key={index}
                  className={`accordion-item ${activeIndex === index ? "active" : ""}`}
                >
                  <div className="accordion-header" onClick={() => toggleAccordion(index)}>
                    <h3>{itineraryTitles[index] || `Day ${index + 1}`}</h3>
                    <span>{activeIndex === index ? <FaMinus size={20}/> : <FaPlus size={20}/>}</span>
                  </div>
                  {activeIndex === index && (
                    <div className="accordion-body justify">
                      {item.paragraphs.map((paragraph, pIndex) => (
                        <p key={pIndex}>{paragraph}</p>
                      ))}
                    </div>
                  )}
                </div>
              ))}
        </div>
        <div className='container'>
          <div className='row'>
            <div className='col-12 col-md-6 col-lg-6 col-xl-6'>
              <h3 className='mt-3' style={{color: 'green', fontWeight:'bold'}}>Inclusions</h3>
              <ul>
                {inclusions.map((item, index) => <li key={index}>{item || ""}</li>)}
              </ul>
            </div>
            <div className='col-12 col-md-6 col-lg-6 col-xl-6'>
              <h3 className='mt-3' style={{color: 'red', fontWeight:'bold'}}>Exclusions</h3>
              <ul>
                {exclusions.map((item, index) => <li key={index}>{item || ""}</li>)}
              </ul>
            </div>
          </div>
        </div>
        <div className='container mb-5'>
          <div className='row'>
          {paymentTerms.length > 0 && (
            <div className='col-12 col-md-12 col-xl-12 col-lg-12'>
              <h3 className='mt-3' style={{color: '#8B4513', fontWeight:'bold'}}>Payment Terms</h3>
              <ul>
                {paymentTerms.map((item, index) => <li key={index} >{item || ""}</li>)}
              </ul>
            </div>
          )}
          {cancellationTerms.length > 0 && (
            <div className='col-12 col-md-12 col-xl-12 col-lg-12'>
              <h3 className='mt-3' style={{color: '#8B4513', fontWeight:'bold'}}>Cancellation Terms</h3>
              <ul className='mb-3'>
                {cancellationTerms.map((item, index) => <li key={index}>{item || ""}</li>)}
              </ul>
            </div>
          )}
          </div>
        </div>
        </div>
        <div className="package-image-container">
        <img
          src="https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/packageTemplate%2Fimg-2-1.jpg?alt=media&token=0b3c24b4-5369-4297-a276-489954fca122"
          alt="Contact"
          className="background-image-package shake-image"
        />
        <div className="package-contact-wrapper shake-text">
          <div className="package-contact-details mb-5">
            <h1 className='text-center'>Contact Us</h1>
            <div className="contact-divider"></div>
            <p className='mt-3'><FaPhoneVolume /> {contact}</p>
            <p><AiOutlineMail /> {email}</p>
          </div>
            <div className="package-contact-details mb-5">
              <h1 className="text-center">Follow Us</h1>
              <div className="contact-divider"></div>
              <div className="social icons-container-footer mt-3">
                <a href="https://wa.me/8792273808" target="__blank" aria-label="WhatsApp">
                  <div className="nav-icon-footer text" style={{color:'white',backgroundColor: "#25D366"}}>
                    <FaWhatsapp size={20}/>
                  </div>
                </a>
                <a href="https://www.facebook.com/travelunboundedcom" target="__blank" aria-label="Facebook">
                  <div className="nav-icon-footer text" style={{color:'white',backgroundColor: "#1877F2"}}>
                    <AiOutlineFacebook size={20} />
                  </div>
                </a>
                <a href="https://www.instagram.com/travel_ub/" target="__blank" aria-label="Instagram">
                  <div className="nav-icon-footer text" style={{color:'white',transition: "background-color 0.3s ease",background: "radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%)"}}>
                    <FaInstagram size={20} />
                  </div>
                </a>
                <a href="https://www.linkedin.com/company/travel-unbounded/" target="__blank" aria-label="LinkedIn">
                  <div className="nav-icon-footer text" style={{color:'white',backgroundColor: "#0077B5"}}>
                    <AiOutlineLinkedin size={20} />
                  </div>
                </a>
                <a href="https://www.youtube.com/@travelunbounded668/" target="__blank" aria-label="Youtube">
                  <div className="nav-icon-footer text" style={{color:'white', backgroundColor: "#FF0000"}}>
                    <FaYoutube size={20} />
                  </div>
                </a>
              </div>
              <p className="mt-3 text-center"><AiOutlineCopyrightCircle /> Travel Unbounded</p>
            </div>
          </div>
        </div>
        <footer>
          <Link to='/form'>
            <button className='package-book-button mt-5'>Book Now</button>
          </Link>
        </footer>
      </div>
      </HelmetProvider>
    </>
  );
};

export default PackageDetails;
