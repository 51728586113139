import React, { useState,useEffect,useRef } from 'react';
import './index.css';
import Navbar from '../Nav';
import SideBar from '../SidebarMenu4';
import { IoIosArrowRoundDown } from "react-icons/io";
import { BsBrightnessHighFill } from "react-icons/bs";
import { IoHomeOutline } from "react-icons/io5";
import AfricaGallery from '../AfricaGallery'
import {Link } from 'react-router-dom';
import { HelmetProvider, Helmet } from 'react-helmet-async';

const images1 = [
  '/africa-4/gallery-1/a-4-g-1-1.jpg',
  '/africa-4/gallery-1/a-4-g-1-2.jpg',
  '/africa-4/gallery-1/a-4-g-1-3.jpg',
  '/africa-4/gallery-1/a-4-g-1-4.jpg'
]

const images2 = [
  '/africa-4/gallery-2/a-4-g-2-1.jpg',
  '/africa-4/gallery-2/a-4-g-2-2.jpg',
  '/africa-4/gallery-2/a-4-g-2-3.jpg',
  '/africa-4/gallery-2/a-4-g-2-4.jpg'
]

const images3 = [
  '/africa-4/gallery-3/a-4-g-3-1.jpg',
  '/africa-4/gallery-3/a-4-g-3-2.jpg',
  '/africa-4/gallery-3/a-4-g-3-3.jpg',
  '/africa-4/gallery-3/a-4-g-3-4.jpg',
]

const images4 = [
  '/africa-4/gallery-4/a-4-g-4-1.jpg',
  '/africa-4/gallery-4/a-4-g-4-2.jpg',
  '/africa-4/gallery-4/a-4-g-4-3.jpg',
  '/africa-4/gallery-4/a-4-g-4-4.jpg',
  '/africa-4/gallery-4/a-4-g-4-5.jpg',
]

const images5 = [
  '/africa-4/gallery-5/a-4-g-5-2.jpg',
  '/africa-4/gallery-5/a-4-g-5-3.jpg',
  '/africa-4/gallery-5/a-4-g-5-4.jpg',
  '/africa-4/gallery-5/a-4-g-5-5.jpg',
]

const images6 = [
  '/africa-4/gallery-6/a-4-g-6-1.jpg',
  '/africa-4/gallery-6/a-4-g-6-2.jpg',
  '/africa-4/gallery-6/a-4-g-6-3.jpg',
  '/africa-4/gallery-6/a-4-g-6-4.jpg',
  '/africa-4/gallery-6/a-4-g-6-5.jpg',
]

const preloadImages = (imageUrls) => {
  imageUrls.forEach((url) => {
    const img = new Image();
    img.src = url;
  });
};

const Africa3 = () => {

  const sec0 = useRef(null);
  const sec1 = useRef(null);
  const sec2 = useRef(null);
  const sec3 = useRef(null);
  const sec4 = useRef(null);
  const sec5 = useRef(null);
  const sec6 = useRef(null);
  const sec7 = useRef(null);

  const scrollHandler = (elemRef) => {
    window.scrollTo({ top: elemRef.current.offsetTop, behavior: 'smooth', block: 'start' });
    toggleMenu();
  };

  const scroll = (elemRef) => {
    window.scrollTo({ top: elemRef.current.offsetTop, behavior: 'smooth', block: 'start' });
  };
  

  const [isMenuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  const [activeSection, setActiveSection] = useState(null);

  const handleScroll = () => {
    const sections = document.querySelectorAll('.section-container');

    sections.forEach((section) => {
      const rect = section.getBoundingClientRect();
      if (rect.top <= 100 && rect.bottom >= 300) {
        setActiveSection(section.id);
      }
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const allImages = [...images1, ...images2, ...images3, ...images4, ...images5, ...images6];
    preloadImages(allImages);
  }, []);

  return (
    <>
    <HelmetProvider>
     <Helmet>
        <title>GREATER KRUGER DISCOVERY</title>
        <meta
          name="description"
          content="Siviti Timbavati Homestead in Thornybush Nature Reserve and Tulela Safari Lodge in Klaserie Private Game Reserve
        The two lodges are for exclusive use of the group."
        />
    </Helmet>
    <div className='tour-app'>
      <div className='section-container' ref={sec0}>
        <div className='fixed-image' style={{ backgroundImage: 'url(/africa-4/a-1.png)'}}>
        
        </div>
        <div className='content'>
          <SideBar isOpen={isMenuOpen} toggleMenu={toggleMenu}  refs={{ sec0, sec1, sec2, sec3, sec4, sec5, sec6, sec7 }}
          scrollHandler={scrollHandler}/>
          <Navbar toggleMenu={toggleMenu} isOpen={isMenuOpen} />
          <div className='content-flex'>
            <h2>“A SAFARI IS NOT JUST A JOURNEY INTO THE WILD,
            BUT A JOURNEY INTO THE SOUL OF AFRICA.”</h2>
            <p>- Boyd Norton</p>
            <IoIosArrowRoundDown size={90} style={{ cursor: 'pointer',marginTop:'50px'}} onClick={() => scroll(sec1)}/>
          </div>
        </div>
      </div>

      <div className='section-container' id='overview' ref={sec1}>
        <div className='fixed-image' style={{ backgroundImage: 'url(/africa-4/a-2.jpg)' }}>
        </div>
        <div className='content'>
          <SideBar isOpen={isMenuOpen} toggleMenu={toggleMenu}  refs={{ sec0, sec1, sec2, sec3, sec4, sec5, sec6, sec7 }}
          scrollHandler={scrollHandler}/>
          <Navbar toggleMenu={toggleMenu} isOpen={isMenuOpen} />
          <div className='content-flex-2'>
            <div className={`fixed-header-2 ${activeSection === 'overview' ? 'fixed' : ''}`} >
            <h4 className='section'>Overview</h4>
            <hr style={{width:'100%'}}/>
            <h6>Itinerary at a glance</h6>
            </div>
        <div className='scroll'>
          <p style={{fontWeight:'bold'}}>Day 1</p>
          <p>Upon arrival at Eastgate airport, you will be met and greeted by our driver. He will then transfer you to your first lodge in Thornybush Nature Reserve.</p>
          <p style={{fontWeight:'bold'}}>Day 1 - 4</p>
          <p>You will spend 3 nights in the beautiful Timbavati Homestead, your home away from home, which will be fully catered and exclusive.
          On your first day, you will have a sunset game drive before returning to the lodge on time to freshen up and enjoy a delicious dinner.</p>
          <p>Every day, you will be going on sunrise and sunset game drives, looking for wild animals with your knowledgeable guide and tracker who will answer all your questions and tell you fascinating information.</p>
          <p>On your last day and after your last game drive in Thornybush, you will enjoy breakfast at the lodge before being picked up by your driver.</p>
          <p>Siviti Timbavati Homestead </p>
          <p>3 Nights</p>
          <p style={{fontWeight:'bold'}}>Day 4</p>
          <p>You will be transferred to your second lodge in the Klaserie Private Game reserve.</p>
          <p style={{fontWeight:'bold'}}>Day 4 - 8</p>
          <p>The game drives program is similar to the previous lodge's, but this is a different reserve, which your guide and tracker will make you discover with all the passion and expertise to ensure the best possible sightings. Every day is different and nature is unpredictable, and this is part of the thrill of being on safari!</p>
          <p>On your last morning, your driver will pick you up from your lodge and transfer you to Eastgate airport on time for your flight. The time will depend on your flight time but you will still be able to enjoy a sunrise game drive.</p>
          <p>Tulela Safari Lodge</p>
          <p>4 nights</p>
          <p style={{fontWeight:'bold'}}>Day 8</p>
          <p>It is sadly time to say goodbye to South Africa, unless you plan to visit Cape Town, which is only a short flight away, for a change of scenery with many unique landmarks, excursions and renown cuisine.
          Alternatively, you may fly to Victoria Falls in Zimbabwe, one of the seven natural wonders of the world, which is another bucket list destination.</p>
          <p>We hope you will leave Africa with memories of a lifetime and perhaps even the desire to return, for once you caught the safari bug, it never leaves you!</p> 
            </div>
          </div>
        </div>
      </div>

      <div className='section-container' id='map' ref={sec2}>
        <div className='fixed-image' style={{ backgroundImage: 'url(/africa-4/a-3.jpg)' }}> 
        </div>
        <div className='content'>
          <SideBar isOpen={isMenuOpen} toggleMenu={toggleMenu}  refs={{ sec0, sec1, sec2, sec3, sec4, sec5, sec6, sec7 }}
          scrollHandler={scrollHandler}/>
          <Navbar toggleMenu={toggleMenu} isOpen={isMenuOpen} />
          <div className='content-flex-2'>
          <div className={`fixed-header-2 ${activeSection === 'map' ? 'fixed' : ''}`} >
          <h4 className='section'>MAP</h4>
            <hr style={{width:'100%'}}/>
            <h6>Visualize Your Journey</h6>
            </div>
            <div className='scroll'>
             <img src='/africa-4/a-4-map-1.jpeg' alt='' className='map-2'/>
             <p style={{fontWeight:'bold',marginTop:'10px'}}>Key Dates</p>
             <p>1) Day 1 - 4: Siviti Timbavati Homestead, Thornybush Nature Reserve</p>
             <p>2) Day 4 - 8: Tulela Safari Lodge, Klaserie Private Game Reserve</p>
            </div>
          </div>
        </div>
      </div>

      <div className='section-container' id='timbavati' ref={sec3}>
        <div className='fixed-image' style={{ backgroundImage: 'url(/africa-4/a-4-1.jpg)' }}> 
        </div>
        <div className='content'>
          <SideBar isOpen={isMenuOpen} toggleMenu={toggleMenu}  refs={{ sec0, sec1, sec2, sec3, sec4, sec5, sec6, sec7 }}
          scrollHandler={scrollHandler}/>
          <Navbar toggleMenu={toggleMenu} isOpen={isMenuOpen} />
          <div className='content-flex-3'>
          <div className={`fixed-header ${activeSection === 'timbavati' ? 'fixed' : ''}`} >
            <h4 className='section'>Thornybush Nature Reserve</h4>
            <hr style={{width:'100%'}}/>
            <h6>Aria Overview</h6>
            </div>
            <div className='scroll-2'>
             <p>Thornybush Nature Reserve is part of the Greater Kruger National Park and is famed for its huge variety of wildlife including the Big 5. This 13,70000-hectare protected area is the most recent private reserve to be incorporated into the one-million-hectare transfrontier conservation area that joins South Africa, Zimbabwe and Mozambique, allowing animals to move freely across a huge expanse of protected wilderness. This unique position in the conservation landscape allows guests an exclusive game viewing experience, with a limited number of lodges and visitors, offering intimate, uncrowded encounters with wildlife.</p>
             <div className='image-flex'>
             <img src='/africa-4/a-1-new.jpeg' alt='' className='map-1'/>
             <img src='/africa-4/a-3.jpeg' alt='' className='map-1'/>
             </div>
             <div className='image-flex'>
             <img src='/africa-1/africa-sec-2-4.jpg' alt='' className='map-1'/>
             <img src='/africa-4/a-2.jpeg' alt='' className='map-1'/>
             </div>
             <p style={{paddingTop:'30px'}}>The wildlife density of Thornybush is excellent, especially noting lion and leopard numbers - two cat species that have become famously comfortable and habituated to the presence of vehicles over decades of respectful and ethical safari practice in the reserve.</p>
            <p>The natural ecosystem of Thornybush supports a diversity of species in a thriving environment, which guests can explore on guided game drives and bush walks. Professional field guides, trackers, and trails guides are available at the reserve's variety of luxury safari lodges and boutique tented camps, shining a light on the intricate workings of the bush.</p>
            </div>
          </div>
        </div>
      </div>

      <div className='section-container' id='thabamati' ref={sec4}>
        <div className='fixed-image' style={{ backgroundImage: 'url(/africa-4/a-5-1.jpg)' }}> 
        </div>
        <div className='content'>
          <SideBar isOpen={isMenuOpen} toggleMenu={toggleMenu}  refs={{ sec0, sec1, sec2, sec3, sec4, sec5, sec6, sec7 }}
          scrollHandler={scrollHandler}/>
          <Navbar toggleMenu={toggleMenu} isOpen={isMenuOpen} />
          <div className='content-flex-3'>
          <div className={`fixed-header ${activeSection === 'thabamati' ? 'fixed' : ''}`} >
            <h4 className='section'>Siviti Timbavati Homestead </h4>
            <hr style={{width:'100%'}}/>
            </div>
            <div className='scroll-2'>
              <h6>Property Overview</h6>
            <p>The Siviti Timbavati Homestead is an exclusive-use home in the Thornybush Private Nature Reserve, part of the Greater Kruger National Park. Located on the banks of the Timbavati River, it is a private homestead catering to one group or family at a time, in four spacious en-suite bedrooms. It has an open-plan kitchen at the heart of the home, an upstairs and downstairs lounge area, and a bar. Complete with a large veranda, swimming pool, fire-pit, and a lawn, this truly is a home in the bush.</p>
            <AfricaGallery images={images1} className='africa-gallery'/>
            <p>The Homestead forms part of Siviti's family-focused offering and children of any age are welcome. With plenty of space to spread out and relax as a family, there is a real sense of personalisation and a self-defined schedule at the camp. Children over six years old are welcome on game drives, and children below 6 years old can join a game drive at the discretion of the guide. </p>
            <hr style={{width:'100%'}}/>
              <h6>Experience & Activities</h6>
              <p>Twice daily game drives at Siviti present an opportunity to enjoy unrivalled game-viewing experiences.</p>
              <AfricaGallery images={images2} className='africa-gallery'/>
              <p>Whether it’s the Big 5, Little 5, the birds or the trees that call to the nature-loving spirit of guests at Siviti, all is found in abundance in the natural environment explored on game drive. The guide and tracker in charge of guests' wildlife experience will make every effort to make this safari a once-in-a-lifetime experience. Sundowner and coffee stops add to the enjoyment of the drives, whilst enjoying a collection of exciting bush-tales from the field guides are best told around the campfire. </p>
              <hr style={{width:'100%'}}/>
              <h6>Accommodations</h6>
              <p style={{fontWeight:'bold',fontStyle:'italic'}}>Guest Rooms</p>
              <p>Siviti Homestead has four spacious guest bedrooms, each with en-suite bathrooms, cool, natural interiors, complete with king-size or twin beds, air-conditioning, and bathrooms with luxury amenities for guests to enjoy. The house has large shared living areas and outdoor spaces from which to enjoy the passing of the wild world outside in total privacy and exclusivity.</p>
              <AfricaGallery images={images3} className='africa-gallery' style={{paddingBottom:'20px'}}/>
            
              <hr style={{width:'100%'}}/>
              <h6 style={{paddingBottom:'20px'}}>Fast Facts</h6>
              <div className='highlights'>
              <BsBrightnessHighFill size={50} style={{marginRight:'50px'}}/>
              <div>
                <p style={{fontWeight:'bold'}}>Highlights</p>
                <p>Premier location in Thornybush Nature Reserve.</p>
                <p>Exclusive-use 4-bedroom safari home.</p>
                <p>Family-friendly - children of all ages welcome.</p>
                <p>Private guide, vehicle, and chef in-house.</p>
              </div>
              </div>
              </div>
          </div>
        </div>
      </div>

      <div className='section-container' id='game' ref={sec5}>
        <div className='fixed-image' style={{ backgroundImage: 'url(/africa-4/a-6-1.jpg)' }}> 
        </div>
        <div className='content'>
          <SideBar isOpen={isMenuOpen} toggleMenu={toggleMenu}  refs={{ sec0, sec1, sec2, sec3, sec4, sec5, sec6, sec7 }}
          scrollHandler={scrollHandler}/>
          <Navbar toggleMenu={toggleMenu} isOpen={isMenuOpen} />
          <div className='content-flex-3'>
          <div className={`fixed-header ${activeSection === 'game' ? 'fixed' : ''}`} >
            <h4 className='section'>Klaserie Private Game Reserve</h4>
            <hr style={{width:'100%'}}/>
            <h6>Aria Overview</h6>
            </div>
            <div className='scroll-2'>
              <p>Part of the Greater Kruger Park area, Klaserie Private Game Reserve is one of the largest privately owned reserves in South Africa, covering an impressive 148,000 acres of pristine wildlife. It is home to the Big Five - lion, leopard, elephant, buffalo and rhino - as well as some of Africa’s less spotted and endangered species like wild dog and cheetah. The landscape is varied and includes typical Acacia savanna, riverine bushveld, as well as vast stretches of mopane woodland.</p>
              <img src='/africa-2/africa-sec-1-1.jpg' alt='' className='map'/>
             <div className='image-flex'>
             <img src='/africa-2/africa-sec-1-2.jpg' alt='' className='map-1'/>
             <img src='/africa-2/a-4.jpeg' alt='' className='map-1'/>
             </div>
             <p>Klaserie is fiercely focused on nature conservation and low-impact tourism. Limited visitor numbers allow for a more exclusive experience with more time at sightings and fewer other vehicles around. Guests can enjoy plains game on the open veld while the Klaserie River is popular with hippo pods, playful elephants, buffalo, and crashes of rhino.</p>
             <p>The Kruger National Park, the Panorama Route, Blyde River Canyon, and various endangered species centers are all a day-trip away. Bush walks and learning to track big game on foot may also be possible.</p>
            </div>
          </div>
        </div>
      </div>

      <div className='section-container' id='siviti' ref={sec6}>
      <div className='fixed-image' style={{ backgroundImage: 'url(/africa-4/a-7.jpg)' }}> 
      </div>
        <div className='content'>
          <SideBar isOpen={isMenuOpen} toggleMenu={toggleMenu}  refs={{ sec0, sec1, sec2, sec3, sec4, sec5, sec6, sec7 }}
          scrollHandler={scrollHandler}/>
          <Navbar toggleMenu={toggleMenu} isOpen={isMenuOpen} />
          <div className='content-flex-3'>
          <div className={`fixed-header ${activeSection === 'siviti' ? 'fixed' : ''}`} >
            <h4 className='section'>Tulela Safari Lodge</h4>
            <hr style={{width:'100%'}}/>
            </div>
            <div className='scroll-2'>
              <h6>Property Overview</h6>
            <p>Tulela is an exclusive-use safari lodge offering exceptional comfort and luxury to families and friends in a private location in the Greater Kruger Park.</p>
            <p>The lodge is designed to inspire a feeling of peace and contentment. Settle into the comfort and convenience of a world-class safari lodge, enjoy the view of our exquisite surroundings, and let the team at Tulela take care of every need. </p>
            <AfricaGallery images={images4} className='africa-gallery'/>
            <p >The thatched main area is nestled in the shade of old indigenous trees on a bend in the river that truly immerse the bedrooms and guest areas in tranquil nature. </p>
            <p>Tulela can host 10 guests in five luxurious bedrooms and has a spacious, central living area, which draws people together around the rim-flow swimming pool, fire pit, and experiential dining area.</p>
            <p>A fully furnished kitchen and easily accessible refreshments area completes the communal living and dining space, which flows onto the deck and makes the most of the view. </p>
            <p>Tulela's very own “amphitheatre” invites wildlife to drink at a waterhole positioned in full view of the lodge, so that guests can sit back, relax, and watch as nature’s stories unfold. </p>
            <hr style={{width:'100%'}}/>
              <h6>Experience & Activities</h6>
              <p>The Tulela experience offers a host of different ways to explore the luxury of being in the bush. Each day can be tailored to the moment, and planned to suit guests' desires. Spend as much time in the bush or relaxing at the lodge as is preferred, and at night, when the lions call, there's always the option to jump in the vehicle and find them patrolling in the night.</p>
              <p>Whether exploring the Klaserie on foot, or spending a night sleeping out on Tulela's private Star Deck, or brunching in the bush under a jackalberry tree, Tulela strives to bring guests closer to nature and create memories that last a lifetime. </p>
              <AfricaGallery images={images5} className='africa-gallery'/>
             
              <hr style={{width:'100%'}}/>
              <h6>Accommodations</h6>
              <p style={{fontWeight:'bold', fontStyle:'italic'}}>Rooms</p>
              <p>There are five luxuriously appointed rooms, two of which have an inter-leading door and double as a family suite. Three are positioned overlooking the riverbed and two have a view of the waterhole. </p>
              <p>The light and spacious rooms have 4-poster king-size beds, which are convertible to twins. In-room amenities include a cupboard, dressing table, air-conditioning, and ceiling fans. Every bedroom opens up onto its own deck.</p>
              <p>Each room leads into an en-suite bathroom with double vanity, indoor shower and bath tub, and an outdoor shower in a space shared by green plants with open sky and trees overhead. </p>
              <AfricaGallery images={images6} className='africa-gallery'/>
            
              <hr style={{width:'100%'}}/>
              <h6 style={{paddingBottom:'20px'}}>Fast Facts</h6>
              <div className='highlights'>
              <BsBrightnessHighFill size={70} style={{marginRight:'50px'}}/>
              <div>
                <p style={{fontWeight:'bold'}}>Highlights</p>
                <p>A tailored safari experience designed to guests' preference.</p>
                <p>Tulela is nestled beneath tall, ancient trees on a bend of the N’tsiri River.</p>
                <p>Wildlife comes to drink at a waterhole in full view of the lodge.</p>
                <p>Unlimited game viewing with guide, tracker, and access to 6,000 hectares of Greater Kruger.</p>
                <p>Get involved with conservation projects such as wild dog collaring and rhino dehorning.</p>
              </div>
              </div>
              <div className='highlights'>
              <IoHomeOutline size={30} style={{marginRight:'60px'}}/>
              <div>
                <p style={{fontWeight:'bold'}}>Quick Facts</p>
                <p>5 rooms</p>
                <p>Accommodates 10 guests </p>
                <p>Air-conditioning</p>
                <p>Dining area</p>
                <p>Wi-fi  </p>
                <p>Luxury fittings throughout the lodge and rooms</p>
                <p>Boma and pool with riverfront views </p>
                <p>Private waterhole </p>
                <p>Fenced-in garden </p>
                <p>Star Deck for sleep-outs </p>
                <p>Activities for children</p>
              </div>
              </div>
              </div>
          </div>
        </div>
      </div>
      <div className='section-container' id='cost' ref={sec7}>
        <div className='fixed-image' style={{ backgroundImage: 'url(/africa-1/africa-8-1.jpg)' }}>
        </div>
        <div className='content'>
          <SideBar isOpen={isMenuOpen} toggleMenu={toggleMenu}  refs={{ sec0, sec1, sec2, sec3, sec4, sec5, sec6, sec7 }}
          scrollHandler={scrollHandler}/>
          <Navbar toggleMenu={toggleMenu} isOpen={isMenuOpen} />
          <div className='content-flex-3'>
          <div className={`fixed-header ${activeSection === 'cost' ? 'fixed' : ''}`} >
            <h4 className='section'>Costs</h4>
            <hr style={{width:'100%'}}/>
            </div>
            <div className='scroll-2'>
              <p style={{fontWeight:'bold'}}>Group of 6 pax : Min 6 paxs required, USD 4660 per pax</p>
              <p>This quote is for 6 people and up to 10 people in shared accommodation. It is also quoted for adults only. Please enquire if children are part of the group.</p>
             <p style={{fontWeight:'bold'}}>Notes</p>
             <p>- Accommodation is subject to availability at the time of booking.</p>
             <p>- Rates quoted are subject to change without prior notice by third party suppliers.</p>
             <p>- Due to increasing fuel prices, the road transfer rates are subject to change without notice.</p>
             <p style={{fontWeight:'bold'}}>This cost includes:</p>
             <p>All transfers to, from Eastgate airport and between lodges.</p>
             <p>Thornybush and Klaserie reserves conservation fees</p>
             <p>All meals, both lodges are fully catered with private chef</p>
             <p>Two game drives per full day</p>
             
             <p>At Siviti: </p>
             <p>- all local drinks (soft & alcoholic drinks)</p>
            <p style={{fontWeight:'bold'}}>This cost does not include:</p>
            <p>International flights</p>
            <p>Internal flights to and from Eastgate</p>
            <p>Medical and Travel insurance</p>
            <p>Tips</p>
            <p>Items of personal nature </p>
            <p>Anything not mentioned above</p>
            <p>At Tulela:</p>
            <p>- all drinks</p>
             <p style={{fontWeight:'bold'}}>Note:</p>
             <p><b>Season:</b> We have a wet season when it is very hot, humid and can see big rains at times from October/November to April. From May until September/October, we have the dry season with morning, evening and night getting colder but the days are very pleasant and warm. July / August is the peak game viewing season as the bush is dry, animals gather around waterholes and are easier to spot. This is also a great season for walking safaris.</p>
             <p><b>Rates:</b> there are set rates throughout the year except at peak seasons, which is usually December and Easter holidays, where no special applies and rates might be higher, it is also a busier time in lodges and the reserves.</p>
             <p style={{fontStyle:'italic', color:'#ccc'}}>Powered by ,<br/> Travel Unbounded</p>
            </div>
            <div className="text-center-africa">
              <Link to="/form">
                <button type="button" className="book-btn">
                  Book Now
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
    </HelmetProvider>
    </>
  );
}

export default Africa3;
