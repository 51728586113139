import React, { useState } from "react";
import { FaWhatsapp, FaInstagram} from "react-icons/fa";
import {
  AiOutlineMail,
  AiOutlineLinkedin,
  AiOutlineFacebook,
  AiOutlineCopyrightCircle,
} from "react-icons/ai";
import { FaYoutube } from "react-icons/fa";
import { ImLocation2 } from "react-icons/im";
import { BsArrowLeftSquareFill } from "react-icons/bs";
import { Link } from "react-router-dom";
//import Scroll from "../Scroll";
import "./footer.css";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { toast, Toaster } from "react-hot-toast";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
};

firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();

const Footer = () => {
  const [email, setEmail] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await db.collection("news-letter").add({
        email: email,
        time: firebase.firestore.FieldValue.serverTimestamp(),
      });
      toast.success("Subscribed to News Letter Successfully");
      setEmail("");
      console.log("Email added successfully");
    } catch (error) {
      console.error("Error adding email to Firestore:", error);
      toast.error("Please enter valid mail");
    }
  };

  const handleBack = () => {
    window.history.back();
  };
  return (
    <>
      <Toaster toastOptions={{ duration: 5000 }} />
      <div className="footer-data-container">
      <div className="newsletter-container">
      <div className="newsletter-content">
        <div className="newsletter-icon">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="48" height="48" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-bell">
            <path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"/>
            <path d="M13.73 21a2 2 0 0 1-3.46 0"/>
          </svg>
        </div>
        <div className="newsletter-text">
          <h3>Get More Updates</h3>
          <p>Subscribe to our News Letter</p>
        </div>
      </div>
      <div className="newsletter-form">
        <form onSubmit={handleSubmit}>
          <input type="email" placeholder="Your Email" value={email} onChange={(e) => setEmail(e.target.value)} required />
          <button>SUBSCRIBE</button>
        </form>
      </div>
      </div> 
        <div className="footer">
          <h2 className="footer-head pt-4">Contact US</h2>
          <div className="footer-container-contact-section mb-3">
            <p className="footer-text">
              <AiOutlineMail /> support@travelunbounded.com
            </p>
            <p className="footer-text">
              <FaWhatsapp /> 9845090014
            </p>
          </div>
          <h2 className="footer-head">Locations</h2>
          <div className="footer-container-locations">
            <div className="address-container">
              <p className="address-head">Registered office</p>
              <p className="footer-text">
                <ImLocation2 />
                No 10, 8th Main, 12th Cross, Vinayaka Nagar B block, Konene
                Agrahara, Bangalore 560017
              </p>
            </div>
            <div className="address-container">
              <p className="address-head">Headquarters </p>
              <p className="footer-text">
                <ImLocation2 />
                No 32, Global Incubation Services, CA Site, No 1, HAL 3rd Stage
                EXTN, behind Hotel Leela Palace, HAL 2nd Stage, Kodihalli,
                Bengaluru, Karnataka 560008
              </p>
            </div>
            <div className="address-container">
              <p className="address-head">Nairobi office</p>
              <p className="footer-text">
                <ImLocation2 />
                Westpark Towers, Muthithi Road, Nairobi, P.O. Box: 6950 Postal
                Code 00100
              </p>
            </div>
          </div>
          <div>
            <div className="right-container-footer"></div>
            <h2 className="footer-head">Social Media</h2>
            <div className="icons-container-footer mt-3">
        <a href="https://wa.me/8792273808" target="__blank" aria-label="WhatsApp">
          <div className="nav-icon-footer text" style={{color:'white',backgroundColor: "#25D366"}}>
            <FaWhatsapp size={20}/>
          </div>
        </a>
        <a href="https://www.facebook.com/travelunboundedcom" target="__blank" aria-label="Facebook">
          <div className="nav-icon-footer text" style={{color:'white',backgroundColor: "#1877F2"}}>
            <AiOutlineFacebook size={20} />
          </div>
        </a>
        <a href="https://www.instagram.com/travel_ub/" target="__blank" aria-label="Instagram">
          <div className="nav-icon-footer text" style={{color:'white',transition: "background-color 0.3s ease",background: "radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%)"}}>
            <FaInstagram size={20} />
          </div>
        </a>
        <a href="https://www.linkedin.com/company/travel-unbounded/" target="__blank" aria-label="LinkedIn">
          <div className="nav-icon-footer text" style={{color:'white',backgroundColor: "#0077B5"}}>
            <AiOutlineLinkedin size={20} />
          </div>
        </a>
        <a href="https://www.youtube.com/@travelunbounded668/" target="__blank" aria-label="Youtube">
          <div className="nav-icon-footer text" style={{color:'white', backgroundColor: "#FF0000"}}>
            <FaYoutube size={20} />
          </div>
        </a>
             {/* <div className="left-container-footer d-none d-md-block d-xl-block d-lg-block">
                <p className="footer-text">Subscribe to News letter</p>
                <form onSubmit={handleSubmit}>
                  <input
                    type="email"
                    className="news-letter-input"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Enter your email"
                    required
                  />
                  <button type="submit" className="subscribe-btn">
                    Subscribe
                  </button>
                </form>
              </div> */}
            </div>
          </div>
          <div className="footer-container-social">
            <Link to="/privacy" className="link">
              <p className="m-2 footer-text">Privacy policy</p>
            </Link>
            <Link to="/terms" className="link">
              <p className="m-2 footer-text">Terms and conditions</p>
            </Link>
            <a
              href="https://www.google.com/search?q=travel+unbounded&rlz=1C1UEAD_enIN1077IN1077&oq=travel&gs_lcrp=EgZjaHJvbWUqBggCEEUYOzIGCAAQRRg8MgwIARBFGDkYsQMYgAQyBggCEEUYOzIGCAMQRRg9MgYIBBBFGDwyBggFEEUYPTIGCAYQRRg8MgYIBxBFGDzSAQg1NDgyajBqN6gCALACAA&sourceid=chrome&ie=UTF-8#lrd=0x3bae1405d293a9a1:0x2593b16dbea01fb6,3,,,,"
              className="link"
              target="__blank"
            >
              <p className="m-2 footer-text">Write a review</p>
            </a>
          </div>
          {/* Mobile View News letter */}
          {/* <div className="text-center mb-3 d-sm-block d-md-none d-xl-none d-lg-none">
            <p className="footer-text">Subscribe to News letter</p>
            <form onSubmit={handleSubmit}>
              <input
                type="email"
                className="news-letter-input"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your email"
                required
              />
              <button type="submit" className="subscribe-btn">
                Subscribe
              </button>
            </form>
          </div> */}
          <div
            style={{
              textAlign: "center",
              marginLeft: "20px",
              color: "white",
              marginBottom: "5px",
            }}
          >
            <BsArrowLeftSquareFill size={30} onClick={handleBack} />
          </div>
          <footer className="footer-color">
            <div className="footer-container-social">
              <p className="footer-text-bottom">
                <AiOutlineCopyrightCircle />
                Travel Unbounded
              </p>
            </div>
          </footer>
        </div>
      </div>
    </>
  );
};
export default Footer;
