import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import Whatsapp from './components/Whatsapp';
import Home from "./components/Home";
import BlogList from "./components/BlogList";
import About from "./components/About";
import BlogDetails from "./components/BlogDetails";
import DestinationKenya from "./components/DestinationKenya";
import DestinationVietnam from "./components/DestinationVietnam";
import DestinationTanzania from "./components/DestinationTanzania";
import DestinationBandhavgarh from "./components/DestinationBandhavgarh";
import DestinationRanthambore from "./components/DestinationRanthambore";
import DestinationCorbett from "./components/DestinationCorbett";
import DestinationKabini from "./components/DestinationKabini";
import DestinationBandipur from "./components/DestinationBandipur";
import Form from "./components/Form";
import Privacy from "./components/Privacy";
import Terms from "./components/Terms";
import Footer from "./components/Footer";
import NotFound from "./components/NotFound";
import SearchNotFound from "./components/SearchNotFound";
import Blog10 from './components/Blog10';
import IcelandBooking from './components/IcelandBooking';
import Africa1 from './components/Africa-1';
import Africa2 from './components/Africa-2';
import Africa4 from './components/Africa-4';
import Africa5 from './components/Africa-5';
import SouthAfrica from './components/SouthAfrica';
import EnquireForm from './components/EnquireForm';
import EnquireButton from "./components/EnquireButton";
import PackageTemplate from "./components/PackageTemplate";
import AddPackages from "./components/AddPackages";
import PackageList from "./components/PackageList";

function App() {
  return (
    <Router>
      <Whatsapp />
      <ScrollToTop/>
      <EnquireButton/>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/kenya" element={<DestinationKenya />} />
        <Route path="/tanzania" element={<DestinationTanzania />} />
        <Route path="/vietnam" element={<DestinationVietnam />} />
        <Route path="/bandhavgarh" element={<DestinationBandhavgarh />} />
        <Route path="/ranthambore" element={<DestinationRanthambore />} />
        <Route path="/corbett" element={<DestinationCorbett />} />
        <Route path="/kabini" element={<DestinationKabini />} />
        <Route path="/bandipur" element={<DestinationBandipur />} />
        <Route path="/about" element={<About />} />
        <Route path="/blogs" element={<BlogList />} />
        <Route path="/blog/10" element={<Blog10 />} />
        <Route path="/south-africa-tour/1" element={<Africa1 />} />
        <Route path="/south-africa-tours" element={<SouthAfrica />} />
        <Route path="/south-africa-tour/2" element={<Africa2 />} />
        <Route path="/south-africa-tour/3" element={<Africa4 />} />
        <Route path="/south-africa-tour/4" element={<Africa5 />} />
        <Route path="/booking-form" element={<IcelandBooking />} />
        <Route path="/blog/:id" element={<BlogDetails />} />
        <Route path="/form" element={<Form />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/contact" element={<Footer />} />
        <Route path="/add-package" element={<AddPackages />} />
        <Route path="/packages" element={<PackageList />} />
        <Route path="/package/:destinationName" element={<PackageTemplate />} />
        <Route path="/enquire-now" element={<EnquireForm />} />
        <Route path="/search-not-found" element={<SearchNotFound />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
