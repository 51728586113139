import React, { useState,useEffect,useRef } from 'react';
import './index.css';
import Navbar from '../Nav';
import SideBar from '../SidebarMenu';
import {Link} from 'react-router-dom'
import { IoIosArrowRoundDown } from "react-icons/io";
import { BsBrightnessHighFill } from "react-icons/bs";
import { IoHomeOutline } from "react-icons/io5";
import AfricaGallery from '../AfricaGallery'
import VideoContainer from '../Youtube';
import { HelmetProvider, Helmet } from 'react-helmet-async';

const images1 = [
  '/africa-1/gallery-1/africa-gallery-1.jpg',
  '/africa-1/gallery-1/africa-gallery-2.jpg',
  '/africa-1/gallery-1/africa-gallery-3.jpg',
  '/africa-1/gallery-1/africa-gallery-4.jpg',
  '/africa-1/gallery-1/africa-gallery-5.jpg'
]

const images2 = [
  '/africa-1/gallery-2/africa-gallery-2-1.jpg',
  '/africa-1/gallery-2/africa-gallery-2-2.jpg',
  '/africa-1/gallery-2/africa-gallery-2-3.jpg',
  '/africa-1/gallery-2/africa-gallery-2-4.jpg',
  '/africa-1/gallery-2/africa-gallery-2-5.jpg'
]

const images3 = [
  '/africa-1/gallery-3/africa-gallery-3.jpg',
  '/africa-1/gallery-3/africa-gallery-3-1.jpg',
  '/africa-1/gallery-3/africa-gallery-3-2.jpg',
  '/africa-1/gallery-3/africa-gallery-3-3.jpg',
  '/africa-1/gallery-3/africa-gallery-3-5.jpg'
]

const images4 = [
  '/africa-1/gallery-4/africa-sec-3-1-1.jpg',
  '/africa-1/gallery-4/africa-sec-3-1-2.jpg',
  '/africa-1/gallery-4/africa-sec-3-1-3.jpg',
  '/africa-1/gallery-4/africa-sec-3-1-4.jpg',
  '/africa-1/gallery-4/africa-sec-3-1-5.jpg'
]

const images5 = [
  '/africa-1/gallery-5/africa-sec-3-2-1.jpg',
  '/africa-1/gallery-5/africa-sec-3-2-2.jpg',
  '/africa-1/gallery-5/africa-sec-3-2-3.jpg',
  '/africa-1/gallery-5/africa-sec-3-2-4.jpg',
  '/africa-1/gallery-5/africa-sec-3-2-5.jpg'
]

const images6 = [
  '/africa-1/gallery-6/africa-sec-3-3-1.jpg',
  '/africa-1/gallery-6/africa-sec-3-3-2.jpg',
  '/africa-1/gallery-6/africa-sec-3-3-3.jpg',
  '/africa-1/gallery-6/africa-sec-3-3-4.jpg',
  '/africa-1/gallery-6/africa-sec-3-3-5.jpg'
]


const preloadImages = (imageUrls) => {
  imageUrls.forEach((url) => {
    const img = new Image();
    img.src = url;
  });
};

const Africa1 = () => {

  const sec0 = useRef(null);
  const sec1 = useRef(null);
  const sec2 = useRef(null);
  const sec3 = useRef(null);
  const sec4 = useRef(null);
  const sec5 = useRef(null);
  const sec6 = useRef(null);
  const sec7 = useRef(null);

  const scrollHandler = (elemRef) => {
    window.scrollTo({ top: elemRef.current.offsetTop, behavior: 'smooth', block: 'start' });
    toggleMenu();
  };

  const scroll = (elemRef) => {
    window.scrollTo({ top: elemRef.current.offsetTop, behavior: 'smooth', block: 'start' });
  };
  

  const [isMenuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  const [activeSection, setActiveSection] = useState(null);

  const handleScroll = () => {
    const sections = document.querySelectorAll('.section-container');

    sections.forEach((section) => {
      const rect = section.getBoundingClientRect();
      if (rect.top <= 100 && rect.bottom >= 300) {
        setActiveSection(section.id);
      }
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const allImages = [...images1, ...images2, ...images3, ...images4, ...images5, ...images6];
    preloadImages(allImages);
  }, []);

  return (
    <>
     <HelmetProvider>
    <Helmet>
        <title>HONEYMOON IN THE GREATER KRUGER</title>
        <meta
          name="description"
          content="Thabamati Luxury Tented Camp in Timbavati Private Nature Reserve and Siviti lodge in Thornybush Game Reserve"
        />
    </Helmet>
    <div className='tour-app'>
      <div className='section-container' ref={sec0}>
        <div className='fixed-image' style={{ backgroundImage: 'url(/africa-1/africa-1-1.jpg)'}}>
        
        </div>
        <div className='content'>
          <SideBar isOpen={isMenuOpen} toggleMenu={toggleMenu}  refs={{ sec0, sec1, sec2, sec3, sec4, sec5, sec6, sec7 }}
          scrollHandler={scrollHandler}/>
          <Navbar toggleMenu={toggleMenu} isOpen={isMenuOpen} />
          <div className='content-flex'>
            <h2>“THE EYE NEVER FORGETS WHAT THE HEART HAS SEEN.”</h2>
            <p>- African Proverb</p>
            <IoIosArrowRoundDown size={90} style={{ cursor: 'pointer',marginTop:'50px'}} onClick={() => scroll(sec1)}/>
          </div>
        </div>
      </div>

      <div className='section-container' id='overview' ref={sec1}>
        <div className='fixed-image' style={{ backgroundImage: 'url(/africa-1/africa-2-1.jpg)' }}>
        </div>
        <div className='content'>
          <SideBar isOpen={isMenuOpen} toggleMenu={toggleMenu}  refs={{ sec0, sec1, sec2, sec3, sec4, sec5, sec6, sec7 }}
          scrollHandler={scrollHandler}/>
          <Navbar toggleMenu={toggleMenu} isOpen={isMenuOpen} />
          <div className='content-flex-2'>
            <div className={`fixed-header-2 ${activeSection === 'overview' ? 'fixed' : ''}`} >
            <h4 className='section'>Overview</h4>
            <hr style={{width:'100%'}}/>
            <h6>Itinerary at a glance</h6>
            </div>
        <div className='scroll'>
          <p style={{fontWeight:'bold'}}>Day 1</p>
          <p>Upon arrival at Eastgate airport, you will be met and greeted by our driver. He will then transfer you to your first lodge in Timbavati Nature Reserve.</p>
          <p style={{fontWeight:'bold'}}>Day 1 - 4</p>
          <p>You will spend 3 nights in the beautiful Thabamati Luxury Tented Camp.</p>
          <p>On your first day, you will have a sunset game drive before returning to the lodge on time to freshen up and enjoy a delicious dinner.</p>
          <p>Every day, you will be going on sunrise and sunset game drives, looking for wild animals with your knowledgeable guide and tracker who will answer all your questions and tell you fascinating information.</p>
          <p>On your last day and after your last game drive in Timbavati, you will enjoy breakfast at the lodge before being picked up by your driver.
          Thabamati Luxury Tented Camp
          3 Nights</p>
          <p style={{fontWeight:'bold'}}>Day 4</p>
          <p>You will be transferred to your second lodge in the Thornybush Game reserve.</p>
          <p style={{fontWeight:'bold'}}>Day 4 - 8</p>
          <p>The game drives program is similar to the previous lodge, but this is a different reserve, which your guide and tracker will make you discover with all the passion and expertise to ensure the best possible sightings. Every day is different and nature is unpredictable, and this is part of the thrill of being on safari!</p>
          <p>On your last morning, your driver will pick you up from your lodge and transfer you to Eastgate airport on time for your flight. The time will depend on your flight time but you will still be able to enjoy a sunrise game drive.</p>
          <p>Siviti</p>
          <p>4 nights</p>
          <p style={{fontWeight:'bold'}}>Day 8</p>
          <p>It is sadly time to say goodbye to South Africa, unless you plan to visit Cape Town, which is only a short flight away, for a change of scenery with many unique landmarks, excursions and renown cuisine.
          Alternatively, you may fly to Victoria Falls in Zimbabwe, one of the seven natural wonders of the world, which is another bucket list destination.</p>
          <p>We hope you will leave Africa with memories of a lifetime and perhaps even the desire to return, for once you caught the safari bug, it never leaves you!</p> 
            </div>
          </div>
        </div>
      </div>

      <div className='section-container' id='map' ref={sec2}>
        <div className='fixed-image' style={{ backgroundImage: 'url(/africa-1/africa-3-1.jpg)' }}> 
        </div>
        <div className='content'>
          <SideBar isOpen={isMenuOpen} toggleMenu={toggleMenu}  refs={{ sec0, sec1, sec2, sec3, sec4, sec5, sec6, sec7 }}
          scrollHandler={scrollHandler}/>
          <Navbar toggleMenu={toggleMenu} isOpen={isMenuOpen} />
          <div className='content-flex-2'>
          <div className={`fixed-header-2 ${activeSection === 'map' ? 'fixed' : ''}`} >
          <h4 className='section'>MAP</h4>
            <hr style={{width:'100%'}}/>
            <h6>Visualize Your Journey</h6>
            </div>
            <div className='scroll'>
             <img src='/africa-1/africa-1-map.jpeg' alt='' className='map-2'/>
             <p style={{fontWeight:'bold',marginTop:'10px'}}>Key Dates</p>
             <p>1) Day 1 - 4: Thabamati Luxury Tented Camp, Timbavati</p>
             <p>2) Day 4 - 8: Siviti, Thornybush Game Reserve</p>
            </div>
          </div>
        </div>
      </div>

      <div className='section-container' id='timbavati' ref={sec3}>
        <div className='fixed-image' style={{ backgroundImage: 'url(/africa-1/africa-4-1.jpg)' }}> 
        </div>
        <div className='content'>
          <SideBar isOpen={isMenuOpen} toggleMenu={toggleMenu}  refs={{ sec0, sec1, sec2, sec3, sec4, sec5, sec6, sec7 }}
          scrollHandler={scrollHandler}/>
          <Navbar toggleMenu={toggleMenu} isOpen={isMenuOpen} />
          <div className='content-flex-3'>
          <div className={`fixed-header ${activeSection === 'timbavati' ? 'fixed' : ''}`} >
            <h4 className='section'>Timbavati</h4>
            <hr style={{width:'100%'}}/>
            <h6>Aria Overview</h6>
            </div>
            <div className='scroll-2'>
             <p>Timbavati, meaning “the place where something sacred came down to Earth from the Heavens,” is a 130,000 acre private game reserve that shares an unfenced border with the Kruger National Park. Forming part of a huge conservation area that spans across international borders and incorporates a number of biomes, the Timbavati remains one of the strongholds for the Big Five; lion, leopard, elephant, buffalo and rhino are all regularly spotted, and even the endangered Cape hunting dog thrives in the area. In addition to over 350 species of birds, there are dozens of reptiles and amphibians.</p>
             <img src='/africa-1/africa-sec-1.jpg' alt='' className='map'/>
             <p style={{paddingTop:'30px'}}>The Timbavati is best known for its white lions, although it is still extremely rare to see them. From here eastwards into Kruger is the only known part of Africa that has the presence of this recessive gene. White lions aside, the Timbavati is regarded as one of South Africa’s premiere game viewing destinations. As well as having a consistently good density of wildlife, the Timbavati has a strong conservation ethos and vehicles numbers are kept to a minimum at wildlife sightings. This means a more exclusive safari experience and at the same time ensures the welfare of the habituated animals.</p>
             <div className='image-flex'>
             <img src='/africa-1/africa-sec-1-2.jpg' alt='' className='map-1'/>
             <img src='/africa-1/africa-sec-1-3.jpg' alt='' className='map-1'/>
             </div>
            </div>
          </div>
        </div>
      </div>

      <div className='section-container' id='thabamati' ref={sec4}>
        <div className='fixed-image' style={{ backgroundImage: 'url(/africa-1/africa-5-1.jpg)' }}> 
        </div>
        <div className='content'>
          <SideBar isOpen={isMenuOpen} toggleMenu={toggleMenu}  refs={{ sec0, sec1, sec2, sec3, sec4, sec5, sec6, sec7 }}
          scrollHandler={scrollHandler}/>
          <Navbar toggleMenu={toggleMenu} isOpen={isMenuOpen} />
          <div className='content-flex-3'>
          <div className={`fixed-header ${activeSection === 'thabamati' ? 'fixed' : ''}`} >
            <h4 className='section'>Thabamati Luxury Tented Camp</h4>
            <hr style={{width:'100%'}}/>
            </div>
            <div className='scroll-2'>
              <h6>Property Overview</h6>
            <p>The intimate Thabamati Luxury Tented Camp is situated within the world-renowned Timbavati Private Nature Reserve in the Greater Kruger National Park. Here you can relax under canopies of canvas and enjoy views of the nearby dam, the bushveld beyond, and the Drakensberg mountain range on the horizon. Accommodating eight guests in four luxury safari-style tents, Thabamati offers a timeless safari feel with modern elegance.</p>
            <AfricaGallery images={images1} className='africa-gallery'/>
            <p>Entering the main area at Thabamati, you will find a spacious open-plan reception and lounge area designed with nature in mind. The camp’s main area comprises an infinity pool, a viewing deck with seating areas, a sunken boma, library corner, curio shop, bar, lounge, and dining area.</p>
            <VideoContainer videoId="86YMg0gtacw" />
            <hr style={{width:'100%'}}/>
              <h6>Experience & Activities</h6>
              <p>Thabamati offers two safari game drives per day, one in the morning and one in the late afternoon/early evening, in a spacious eight-seater game viewer. Guests can immerse themselves in nature while seeking out animals and birds of the Southern African bush and enjoy morning coffee and sunset stops along the way. Each safari activity is led by a qualified field guide who will share their passion and knowledge of the animals, birdlife and vegetation.</p>
              <AfricaGallery images={images2} className='africa-gallery'/>
              <p>Short morning bush walks are offered after breakfast, focusing more on smaller animals and vegetation that guests might not get to observe in detail during game drives. </p>
              <hr style={{width:'100%'}}/>
              <h6>Accommodations</h6>
              <p style={{fontWeight:'bold',fontStyle:'italic'}}>Luxury Tent</p>
              <p>The luxury Java safari-style tents are situated next to a dam and built in such a way that each one has a view of the water. All tents are open-plan and come furnished with a writing desk, seating area, bedroom, ensuite bathroom with a private WC, luxurious free standing bath, and an inside and outside shower. Complimentary toiletries with the scents of Africa are provided for guests.</p>
              <AfricaGallery images={images3} className='africa-gallery' style={{paddingBottom:'20px'}}/>

              <hr style={{width:'100%'}}/>
              <h6 style={{paddingBottom:'20px'}}>Fast Facts</h6>
              <div className='highlights'>
              <BsBrightnessHighFill size={100} style={{marginRight:'50px'}}/>
              <div>
                <p style={{fontWeight:'bold'}}>Highlights</p>
                <p>Situated in the Timbavati Private Nature Reserve that forms part of the Greater Kruger National Park</p>
                <p>Safari-style tents overlook a beautiful dam with amazing views of the Drakensberg mountains
                  Observe the Big Five in their natural surroundings during game drives and bush walks</p>
              </div>
              </div>
              <div className='highlights'>
              <IoHomeOutline size={30} style={{marginRight:'50px'}}/>
              <div>
                <p style={{fontWeight:'bold'}}>Quick Facts</p>
                <p>4 safari-style tents </p>
                <p>Swimming pool</p>
                <p>Bar</p>
                <p>Lounge</p>
                <p>Dining area</p>
                <p>Firepit</p>
                <p>Curio shop</p>
                <p>Viewing deck</p>
                <p>Spa treatments</p>
                <p>Wifi</p>
              </div>
              </div>
              </div>
          </div>
        </div>
      </div>

      <div className='section-container' id='game' ref={sec5}>
        <div className='fixed-image' style={{ backgroundImage: 'url(/africa-1/africa-6-1.jpg)' }}> 
        </div>
        <div className='content'>
          <SideBar isOpen={isMenuOpen} toggleMenu={toggleMenu}  refs={{ sec0, sec1, sec2, sec3, sec4, sec5, sec6, sec7 }}
          scrollHandler={scrollHandler}/>
          <Navbar toggleMenu={toggleMenu} isOpen={isMenuOpen} />
          <div className='content-flex-3'>
          <div className={`fixed-header ${activeSection === 'game' ? 'fixed' : ''}`} >
            <h4 className='section'>Thornybush Game Reserve</h4>
            <hr style={{width:'100%'}}/>
            <h6>Aria Overview</h6>
            </div>
            <div className='scroll-2'>
              <p>Thornybush Game Reserve is part of the Greater Kruger National Park and is famed for its huge variety of wildlife including the Big Five - lion, leopard, elephant, buffalo and rhino. This 34,500 acre private reserve recently dropped fences with its neighbors, allowing animals to move freely across a huge expanse of protected wilderness. Being in the private reserve affords guests an exclusive game viewing experience, with a limited number of lodges and visitors. This allows for intimate, uncrowded encounters with wildlife.</p>
              <div className='image-flex'>
             <img src='/africa-1/africa-sec-2-1.jpg' alt='' className='map-1'/>
             <img src='/africa-1/africa-sec-2-2.jpg' alt='' className='map-1'/>
             </div>
             <div className='image-flex'>
             <img src='/africa-1/africa-sec-2-3.jpg' alt='' className='map-1'/>
             <img src='/africa-1/africa-sec-2-4.jpg' alt='' className='map-1'/>
             </div>
             <p>The game density is excellent, especially lion and leopard, and guests are able to get as close to the action as possible. Bush walks and night drives offer an exciting way to encounter wildlife.</p>
            </div>
          </div>
        </div>
      </div>

      <div className='section-container' id='siviti' ref={sec6}>
      <div className='fixed-image' style={{ backgroundImage: 'url(/africa-1/africa-7-1.jpg)' }}> 
      </div>
        <div className='content'>
          <SideBar isOpen={isMenuOpen} toggleMenu={toggleMenu}  refs={{ sec0, sec1, sec2, sec3, sec4, sec5, sec6, sec7 }}
          scrollHandler={scrollHandler}/>
          <Navbar toggleMenu={toggleMenu} isOpen={isMenuOpen} />
          <div className='content-flex-3'>
          <div className={`fixed-header ${activeSection === 'siviti' ? 'fixed' : ''}`} >
            <h4 className='section'>Siviti</h4>
            <hr style={{width:'100%'}}/>
            </div>
            <div className='scroll-2'>
              <h6>Property Overview</h6>
            <p>Siviti offers an extraordinary safari experience in the Southern section of Thornybush Private Nature Reserve, proudly part of Greater Kruger National Park.</p>
            <p>Set alongside the banks of the Timbavati river, guests will enjoy private views of curious and thirsty animals, specifically elephants, whether overlooking the main deck rim-flow pool or from the shaded waters of your private plunge pool. </p>
            <AfricaGallery images={images4} className='africa-gallery'/>
            <p>With only six private suites, including the superb two-suite Timbavati villa, Siviti is limited to 12 guests, plus the 8-sleeper Timbavati homestead, ensuring an intimate wilderness experience. With elegant furnishings, guests will feel right at home from the moment you arrive.</p>
            <hr style={{width:'100%'}}/>
              <h6>Experience & Activities</h6>
              <p>Twice daily game drives at Siviti present an opportunity to enjoy unrivaled game-viewing experiences. Whether it’s the Big 5, Little 5, or the countless number of bird species that call Thornybush home, a Siviti guide and tracking team will make every effort to make your safari a once-in-lifetime experience. Sundowner and coffee stops add to the enjoyment of the drives, whilst enjoying a collection of exciting bush-tales from the field guides.</p>
              <AfricaGallery images={images5} className='africa-gallery'/>
              <p>Guests can also embark on a scenic flight into Blyde River Canyon. You will be collected from the lodge and taken for a 1.5 hour helicopter trip to explore one of the largest canyons on earth, with the option to land on the top of Blyde and enjoy a quick picnic. You'll then be taken back to Siviti - just in time to depart for your afternoon bumble. </p>
              <hr style={{width:'100%'}}/>
              <h6>Accommodations</h6>
              <p style={{fontWeight:'bold', fontStyle:'italic'}}>suites</p>
              <p>Each of the generously-sized suites radiate elegance and unrestrained luxury. Slide your floor-to-ceiling glass doors open to welcome the African bushveld in, offering a magical wildlife experience from the comfort of an extra length king-size bed or indoor lounge. With infinite views of the perennial Timbavati River, game viewing can also be enjoyed from your decked terrace while skinny dipping in a private pool. The sliding doors enclosing a Victorian bathtub can be opened wide, allowing your imagination to run wild. Make the most of your indoor or outdoor shower, blending in with the sights and sounds of untamed Africa.</p>
              <AfricaGallery images={images6} className='africa-gallery'/>
              
              <hr style={{width:'100%'}}/>
              <h6 style={{paddingBottom:'20px'}}>Fast Facts</h6>
              <div className='highlights'>
              <BsBrightnessHighFill size={70} style={{marginRight:'50px'}}/>
              <div>
                <p style={{fontWeight:'bold'}}>Highlights</p>
                <p>Located in the southern section of Thornybush Private Nature Reserve, part of Greater Kruger National Park</p>
                <p>Unsurpassed game viewing opportunities blended seamlessly with personalized service and prime amenities</p>
                <p>Enjoy spa treatments from the privacy of your luxury suite</p>
              </div>
              </div>
              <div className='highlights'>
              <IoHomeOutline size={30} style={{marginRight:'60px'}}/>
              <div>
                <p style={{fontWeight:'bold'}}>Quick Facts</p>
                <p>4 suites, a 2-suite villa, & 8-person homestead</p>
                <p>Breakfast buffet </p>
                <p>Dining area</p>
                <p>Lounge</p>
                <p>Swimming pool </p>
                <p>Wifi</p>
              </div>
              </div>
              </div>
          </div>
        </div>
      </div>
      <div className='section-container' id='cost' ref={sec7}>
        <div className='fixed-image' style={{ backgroundImage: 'url(/africa-1/africa-8-1.jpg)' }}>
        </div>
        <div className='content'>
          <SideBar isOpen={isMenuOpen} toggleMenu={toggleMenu}  refs={{ sec0, sec1, sec2, sec3, sec4, sec5, sec6, sec7 }}
          scrollHandler={scrollHandler}/>
          <Navbar toggleMenu={toggleMenu} isOpen={isMenuOpen} />
          <div className='content-flex-3'>
          <div className={`fixed-header ${activeSection === 'cost' ? 'fixed' : ''}`} >
            <h4 className='section'>Costs</h4>
            <hr style={{width:'100%'}}/>
            </div>
            <div className='scroll-2'>
              <p style={{fontWeight:'bold'}}>Honeymooners option 1: Minimum 2 pax required, USD 4977 per pax</p>
             <p style={{fontWeight:'bold'}}>Notes</p>
             <p>- Accommodation is subject to availability at the time of booking.</p>
             <p>- Rates quoted are subject to change without prior notice by third party suppliers.</p>
             <p>- Due to increasing fuel prices, the road transfer rates are subject to change without notice.</p>
             <p>This cost includes:</p>
             <p>All transfers to, from Eastgate airport and between lodges.</p>
             <p>Timbavati and Thornybush reserves conservation fees</p>
             <p>All meals</p>
             <p>Two game drives per full day</p>
             <p>At Thabamati:</p>
             <p>- sundowner game drives drinks (all local drinks soft & alcoholic)</p>
             <p>- sundowner game drives drinks (all local drinks soft & alcoholic)</p>
             <p>At Siviti: </p>
             <p>- all local drinks (soft & alcoholic drinks)</p>
             <p>This cost does not include:</p>
             <p>International flights</p>
             <p>Internal flights to and from Eastgate</p>
             <p>Medical and Travel insurance</p>
             <p>Tips</p>
             <p>Items of personal nature </p>
             <p>Anything not mentioned above</p>
             <p>At Thabamati:</p>
             <p>- all drinks (except on game drives)</p>
             <p style={{fontWeight:'bold'}}>Note:</p>
             <p><b>Season:</b> We have a wet season when it is very hot, humid and can see big rains at times from October/November to April. From May until September/October, we have the dry season with morning, evening and night getting colder but the days are very pleasant and warm. July / August is the peak game viewing season as the bush is dry, animals gather around waterholes and are easier to spot. This is also a great season for walking safaris.</p>
             <p><b>Rates:</b> there are set rates throughout the year except at peak seasons, which is usually December and Easter holidays, where no special applies and rates might be higher, it is also a busier time in lodges and the reserves.</p>
             <p style={{fontStyle:'italic', color:'#ccc'}}>Powered by ,<br/> Travel Unbounded</p>
            </div>
            <div className="text-center-africa">
              <Link to="/form">
                <button type="button" className="book-btn">
                  Book Now
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
    </HelmetProvider>
    </>
  );
}

export default Africa1;
