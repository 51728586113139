import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./gallery.css";

const Gallery = ({ images }) => {
  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    slidesToScroll: 1,
    slidesToShow: 1,
    arrows: false,
  };

  return (
    <div className="carousel-container">
      <Slider {...settings}>
        {images.map((image, index) => (
          <div key={index}>
            <img src={image} alt={`gallery ${index}`} className="slick-slide" />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Gallery;
